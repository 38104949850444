unit fo_Produktdetails;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls, WEBLib.StdCtrls, Vcl.Controls,
  WEBLib.Slider, XData.Web.Client, XData.Web.Connection, WEBLib.ExtCtrls;

type
  Tfm_Produktdetails = class(TForm)
    Slider: TImageSlider;
    lbl_Bezeichnung: TLabel;
    lbl_Artikelnr: TLabel;
    WebButton1: TButton;
    WebButton2: TButton;
    ed_Menge: TEdit;
    WebLabel1: TLabel;
    WebHTMLContainer1: THTMLContainer;
    lbl_Preis: TLabel;
    ed_Preis: TEdit;
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
  private
    { Private-Deklarationen }
    Procedure Zeige_Merkliste;
  public
    { Public-Deklarationen }
    Produkt_ID : Integer;
    Procedure Lies_Produktdaten;
  protected procedure LoadDFMValues; override; end;

var
  fm_Produktdetails: Tfm_Produktdetails;

implementation

{$R *.dfm}

uses Globale_Prozeduren, Datenmodul1;

procedure Tfm_Produktdetails.Lies_Produktdaten;
  Procedure OnResult (Response : TXDataClientResponse);
  Var arr : TJSArray;
      obj : TJSObject;
      s   : String;
  begin
    Arr := TJSArray(TJSObject(Response.Result)['value']);
    obj := toObject(arr.Elements[0]);
    lbl_Artikelnr.HTML := '<h3><b>'+String(obj['Artikelnr'])+'</b></h3>';
    lbl_Artikelnr.Caption := String(obj['Artikelnr']);
    lbl_Bezeichnung.HTML := '<h2><b>'+String(obj['Bezeichnung'])+'</b></h2>';
    lbl_Bezeichnung.Caption := String(obj['Bezeichnung']);


    S := String(obj['Preis']);
    if S = '0,00'
      then
        Begin
          lbl_Preis.Caption := 'Preis bitte anfragen';
          ed_Preis.Visible := False;
        End
      Else
        Begin
          lbl_Preis.Caption := 'Preis [EUR]';
          ed_Preis.Visible := True;
          ed_Preis.Text := S;
        End;


    WebHTMLContainer1.HTML.Text := '<b>'+String(obj['Beschreibung'])+'</b>';
    Slider.ImageURLs.Clear;
    if String(obj['URL1']) <> '' then Slider.ImageURLs.Add('http://'+String(obj['URL1']));
    if String(obj['URL2']) <> '' then Slider.ImageURLs.Add('http://'+String(obj['URL2']));
    if String(obj['URL3']) <> '' then Slider.ImageURLs.Add('http://'+String(obj['URL3']));
    if String(obj['URL4']) <> '' then Slider.ImageURLs.Add('http://'+String(obj['URL4']));
    if String(obj['URL5']) <> '' then Slider.ImageURLs.Add('http://'+String(obj['URL5']));
    Slider.RefreshImages;
  end;

begin
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Produktdaten_von_Produkt_ID',[Sprache, Produkt_ID],@OnResult);
end;

procedure Tfm_Produktdetails.WebButton1Click(Sender: TObject);
begin
  ModalResult := mrClose;
  Close;
end;

procedure Tfm_Produktdetails.WebButton2Click(Sender: TObject);
VAR Anz   : Integer;
    S     : String;
    Menge : Integer;
    Err   : Integer;

  procedure DialogProc(AValue: TModalResult);
    begin
      if AValue = mrYes
        then
          Close
        else
          Zeige_Merkliste;
    end;

begin
  // Dieses Produkt auf die Merkliste setzen...
  Anz := Length(Merkliste);
  SetLength(Merkliste,Anz+1);
  Merkliste[Anz].Produkt_ID := Produkt_ID;
  S := ed_Menge.Text;
  Val(S,Menge,Err);
  Merkliste[Anz].Menge := Menge;
  Merkliste[Anz].Artikelnr := lbl_Artikelnr.Caption;
  Merkliste[Anz].Bezeichnung := lbl_Bezeichnung.Caption;
  Merkliste[Anz].Preis       := ed_Preis.Text;

  MessageDlg('Zur Merkliste hinzugefügt. Möchten Sie weitere Produkte suchen ?', mtInformation, [mbYes, mbNo], @DialogProc);
end;

procedure Tfm_Produktdetails.Zeige_Merkliste;
begin
  ModalResult := mrNo;
  Close;
end;

procedure Tfm_Produktdetails.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbl_Bezeichnung := TLabel.Create(Self);
  lbl_Artikelnr := TLabel.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  lbl_Preis := TLabel.Create(Self);
  Slider := TImageSlider.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebButton2 := TButton.Create(Self);
  ed_Menge := TEdit.Create(Self);
  WebHTMLContainer1 := THTMLContainer.Create(Self);
  ed_Preis := TEdit.Create(Self);

  lbl_Bezeichnung.BeforeLoadDFMValues;
  lbl_Artikelnr.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  lbl_Preis.BeforeLoadDFMValues;
  Slider.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  ed_Menge.BeforeLoadDFMValues;
  WebHTMLContainer1.BeforeLoadDFMValues;
  ed_Preis.BeforeLoadDFMValues;
  try
    Name := 'fm_Produktdetails';
    Width := 1400;
    Height := 571;
    CSSLibrary := cssBootstrap;
    ElementClassName := 'card card-body';
    ElementFont := efCSS;
    lbl_Bezeichnung.SetParentComponent(Self);
    lbl_Bezeichnung.Name := 'lbl_Bezeichnung';
    lbl_Bezeichnung.Left := 32;
    lbl_Bezeichnung.Top := 24;
    lbl_Bezeichnung.Width := 126;
    lbl_Bezeichnung.Height := 15;
    lbl_Bezeichnung.Caption := '<Produktbezeichnung>';
    lbl_Bezeichnung.ElementClassName := 'caption';
    lbl_Bezeichnung.HeightPercent := 100.000000000000000000;
    lbl_Bezeichnung.WidthPercent := 100.000000000000000000;
    lbl_Artikelnr.SetParentComponent(Self);
    lbl_Artikelnr.Name := 'lbl_Artikelnr';
    lbl_Artikelnr.Left := 32;
    lbl_Artikelnr.Top := 513;
    lbl_Artikelnr.Width := 61;
    lbl_Artikelnr.Height := 15;
    lbl_Artikelnr.Caption := '<Artikelnr>';
    lbl_Artikelnr.ElementFont := efCSS;
    lbl_Artikelnr.HeightPercent := 100.000000000000000000;
    lbl_Artikelnr.WidthPercent := 100.000000000000000000;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 1080;
    WebLabel1.Top := 507;
    WebLabel1.Width := 37;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Menge';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    lbl_Preis.SetParentComponent(Self);
    lbl_Preis.Name := 'lbl_Preis';
    lbl_Preis.Left := 904;
    lbl_Preis.Top := 507;
    lbl_Preis.Width := 25;
    lbl_Preis.Height := 15;
    lbl_Preis.Caption := 'Preis';
    lbl_Preis.ElementFont := efCSS;
    lbl_Preis.HeightStyle := ssAuto;
    lbl_Preis.HeightPercent := 100.000000000000000000;
    lbl_Preis.WidthPercent := 100.000000000000000000;
    Slider.SetParentComponent(Self);
    Slider.Name := 'Slider';
    Slider.Left := 40;
    Slider.Top := 80;
    Slider.Width := 568;
    Slider.Height := 392;
    Slider.ElementClassName := 'image';
    Slider.Appearance.NavigationStyle := navsThumbnails;
    Slider.Appearance.TransitionEffect := tefSlide;
    Slider.Appearance.Bullets.Opacity := 1.000000000000000000;
    Slider.Appearance.Thumbnails.SizeAspectRatio := True;
    Slider.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    Slider.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    Slider.Color := clWhite;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 1225;
    WebButton1.Top := 15;
    WebButton1.Width := 144;
    WebButton1.Height := 36;
    WebButton1.Caption := 'Schlie'#223'en';
    WebButton1.ChildOrder := 4;
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 1144;
    WebButton2.Top := 504;
    WebButton2.Width := 225;
    WebButton2.Height := 36;
    WebButton2.Caption := 'Auf Merkliste setzen';
    WebButton2.ChildOrder := 4;
    WebButton2.ElementClassName := 'btn btn-primary';
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    ed_Menge.SetParentComponent(Self);
    ed_Menge.Name := 'ed_Menge';
    ed_Menge.Left := 1080;
    ed_Menge.Top := 528;
    ed_Menge.Width := 49;
    ed_Menge.Height := 22;
    ed_Menge.ChildOrder := 6;
    ed_Menge.EditType := weNumeric;
    ed_Menge.ElementClassName := 'form-control';
    ed_Menge.HeightPercent := 100.000000000000000000;
    ed_Menge.Text := '1';
    ed_Menge.WidthPercent := 100.000000000000000000;
    WebHTMLContainer1.SetParentComponent(Self);
    WebHTMLContainer1.Name := 'WebHTMLContainer1';
    WebHTMLContainer1.Left := 632;
    WebHTMLContainer1.Top := 80;
    WebHTMLContainer1.Width := 737;
    WebHTMLContainer1.Height := 392;
    WebHTMLContainer1.ElementClassName := 'form-control';
    WebHTMLContainer1.ChildOrder := 4;
    WebHTMLContainer1.ElementFont := efCSS;
    ed_Preis.SetParentComponent(Self);
    ed_Preis.Name := 'ed_Preis';
    ed_Preis.Left := 904;
    ed_Preis.Top := 528;
    ed_Preis.Width := 73;
    ed_Preis.Height := 22;
    ed_Preis.ChildOrder := 6;
    ed_Preis.EditType := weNumeric;
    ed_Preis.ElementClassName := 'form-control';
    ed_Preis.HeightStyle := ssAuto;
    ed_Preis.HeightPercent := 100.000000000000000000;
    ed_Preis.ReadOnly := True;
    ed_Preis.Text := '1';
    ed_Preis.WidthPercent := 100.000000000000000000;
  finally
    lbl_Bezeichnung.AfterLoadDFMValues;
    lbl_Artikelnr.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    lbl_Preis.AfterLoadDFMValues;
    Slider.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    ed_Menge.AfterLoadDFMValues;
    WebHTMLContainer1.AfterLoadDFMValues;
    ed_Preis.AfterLoadDFMValues;
  end;
end;

end.