{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2022                                      }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.Actions;

{$DEFINE NOPP}

interface

uses
  Classes, Web, JS, WEBLib.Controls;

type
  THTMLEvent = (heClick,heDblClick,heKeypress,heKeydown,heKeyup,heMouseDown,heMouseMove,heMouseUp,heMouseEnter,heMouseLeave,heBlur,heFocus,heChange,heSelect,heInput,heInvalid,heCustom,heNone,heTouchStart,heTouchEnd,heTouchCancel,heTouchMove,heWheel);

  THTMLAction = (actNone, actSetHidden, actRemoveHidden, actToggleHidden, actSetReadOnly, actRemoveReadOnly, actToggleReadOnly, actSetDisabled, actRemoveDisabled, actToggleDisabled, actClear);

  TJSEventParameter = record
    JSEvent: TJSEvent;
  end;

  THTMLEventEvent = procedure(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter) of object;

  THTMLUpdateEvent = procedure(Sender: TObject; Element: TJSHTMLElementRecord; Event: TJSEventParameter; TargetElement: TJSHTMLElementRecord) of object;

  TCustomHTMLEvent = type string;

  TElementArray = array of TJSElement;

  TElementAction = class(TCollectionItem)
  private
    FElementEvent: THTMLEvent;
    FElementID: TElementID;
    FOnExecute: THTMLEventEvent;
    FTag: integer;
    FStopPropagation: boolean;
    FPreventDefault: boolean;
    FElementHandle: TJSElement;
    FElementHandles: TElementArray;
    FControlHandle: TJSElement;
    FHandlePtr: pointer;
    FCustomEvent: TCustomHTMLEvent;
    FEnabled: boolean;
    FSelector: string;
    FControl: TControl;
    FOnUpdate: THTMLUpdateEvent;
    FTargetControl: TControl;
    FTargetID: TElementID;
    FTargetAction: THTMLAction;
    FTargetSelector: string;
    FName: string;
    procedure SetElementID(const Value: TElementID);
    procedure SetSelector(const Value: string);
    procedure SetName(const Value: string);
    procedure SetElementEvent(const Value: THTMLEvent);
  protected
    function GetDisplayName: string; override;
    function DoHandleEvent(Event: TJSEvent): Boolean;
    procedure DoAction(AEvent: TJSEvent; SrcElement, AElement: TJSElement); virtual;
    procedure BindEvent(AEvent: THTMLEvent; AElement: TJSElement); virtual;
    procedure UnBindEvent(AEvent: THTMLEvent; AElement: TJSElement); virtual;
  public
    constructor Create(Collection: TCollection); override;
    destructor Destroy; override;
    procedure Assign(Source: TPersistent); override;
    procedure Bind;
    procedure UnBind;
    property ElementHandle: TJSElement read FElementHandle;
    property ElementHandles: TElementArray read FElementHandles;
  published
    property Control: TControl read FControl write FControl;
    property CustomEvent: TCustomHTMLEvent read FCustomEvent write FCustomEvent;
    property Enabled: boolean read FEnabled write FEnabled default true;
    property Event: THTMLEvent read FElementEvent write SetElementEvent default heClick;
    property ID: TElementID read FElementID write SetElementID;
    property Name: string read FName write SetName;
    property PreventDefault: boolean read FPreventDefault write FPreventDefault default true;
    property Selector: string read FSelector write SetSelector;
    property StopPropagation: boolean read FStopPropagation write FStopPropagation default true;
    property Tag: integer read FTag write FTag default 0;
    property TargetAction: THTMLAction read FTargetAction write FTargetAction default actNone;
    property TargetControl: TControl read FTargetControl write  FTargetControl;
    property TargetID: TElementID read FTargetID write FTargetID;
    property TargetSelector: string read FTargetSelector write FTargetSelector;
    property OnExecute: THTMLEventEvent read FOnExecute write FOnExecute;
    property OnUpdate: THTMLUpdateEvent read FOnUpdate write FOnUpdate;
  end;

  TElementActions = class(TOwnedCollection)
  private
    function GetItems(AIndex: integer): TElementAction;
    procedure SetItems(AIndex: integer; const Value: TElementAction);
    function GetAction(AName: string): TElementAction;
  protected
  public
    constructor Create(AOwner: TComponent); reintroduce;
    function Add: TElementAction; reintroduce;
    function Insert(AIndex: integer): TElementAction; reintroduce;
    property Items[AIndex: integer]: TElementAction read GetItems write SetItems; default;
    property Action[AName: string]: TElementAction Read GetAction;
    function GetActionByName(AName: string): TElementAction;
    function GetByName(AName: string): TElementAction;
    function FindByName(AName: string): TElementAction;
  end;


  THTMLActionEventEvent = procedure(Sender: TObject; AAction: TElementAction; Element: TJSHTMLElementRecord; Event: TJSEventParameter) of object;

  THTMLActionUpdateEvent = procedure(Sender: TObject; AAction: TElementAction; Element: TJSHTMLElementRecord; Event: TJSEventParameter; TargetElement: TJSHTMLElementRecord) of object;


  TElementActionList = class(TComponent)
  private
    FActions: TElementActions;
    FOnUpdate: THTMLActionUpdateEvent;
    FOnExecute: THTMLActionEventEvent;
    procedure SetActions(const Value: TElementActions);
    function GetAction(AName: string): TElementAction;
  protected
    procedure BeforeLoadDFMValues; override;
    function CreateActions: TElementActions; virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure BindActions;
    procedure UnBindActions;
    procedure Loaded; override;
    property Action[AName: string]: TElementAction Read GetAction; default;
    function GetActionByName(AName: string): TElementAction;
    function GetByName(AName: string): TElementAction;
    function FindByName(AName: string): TElementAction;
  published
    property Actions: TElementActions read FActions write SetActions;
    property OnExecute: THTMLActionEventEvent read FOnExecute write FOnExecute;
    property OnUpdate: THTMLActionUpdateEvent read FOnUpdate write FOnUpdate;
  end;

  TWebElementActionList = class(TElementActionList);


implementation

uses
  SysUtils;

{ TElementAction }

function TElementActions.Add: TElementAction;
begin
  Result := TElementAction(inherited Add);
end;

constructor TElementActions.Create(AOwner: TComponent);
begin
  inherited Create(AOwner, TElementAction);
end;

function TElementActions.FindByName(AName: string): TElementAction;
begin
  Result := GetActionByName(AName);
end;

function TElementActions.GetAction(AName: string): TElementAction;
begin
  Result := GetActionByName(AName);
end;

function TElementActions.GetActionByName(AName: string): TElementAction;
var
  i: integer;
begin
  Result := nil;

  for I := 0 to Count - 1 do
    begin
      if CompareText(AName, Items[i].Name) = 0 then
      begin
        Result := Items[i];
        break;
      end;
    end;
end;

function TElementActions.GetByName(AName: string): TElementAction;
begin
  Result := GetActionByName(AName);
  if not Assigned(Result) then
    raise Exception.Create('Action ' + AName + ' not found');
end;

function TElementActions.GetItems(AIndex: integer): TElementAction;
begin
  Result := TElementAction(inherited Items[AIndex]);
end;

function TElementActions.Insert(AIndex: integer): TElementAction;
begin
  Result := TElementAction(inherited Insert(AIndex));
end;

procedure TElementActions.SetItems(AIndex: integer;
  const Value: TElementAction);
begin
  inherited Items[AIndex] := Value;
end;

{ TElementAction }

procedure TElementAction.Assign(Source: TPersistent);
begin
  if (Source is TElementAction) then
  begin
    FEnabled := (Source as TElementAction).Enabled;
    FElementEvent := (Source as TElementAction).Event;
    FElementID := (Source as TElementAction).ID;
    FTag := (Source as TElementAction).Tag;
    FStopPropagation := (Source as TElementAction).StopPropagation;
    FPreventDefault := (Source as TElementAction).PreventDefault;
    FCustomEvent := (Source as TElementAction).CustomEvent;
    FSelector := (Source as TElementAction).Selector;
    FControl := (Source as TElementAction).Control;
    FTargetAction := (Source as TElementAction).TargetAction;
    FTargetControl := (Source as TElementAction).TargetControl;
    FTargetID := (Source as TElementAction).TargetID;
    FTargetSelector := (Source as TElementAction).TargetSelector;
  end;
end;

procedure TElementAction.Bind;
var
  els: TJSNodeList;
  i: integer;
  el: TJSHTMLElement;
begin
  // unbind any previous event
  UnBind;

  if Assigned(Control) then
  begin
    FControlHandle := Control.ElementHandle;
    if Assigned(FControlHandle) then
      BindEvent(Event, FControlHandle);
  end;

  if (ID <> '') then
  begin
    if Assigned(FElementHandle) then
      UnBindEvent(Event, FElementHandle);

    // Event list https://www.w3schools.com/jsref/dom_obj_event.asp
    FElementHandle := document.getElementByID(ID);
    if Assigned(FElementHandle) then
      BindEvent(Event, FElementHandle);
  end;

  if (FSelector <> '') then
  begin
    els := document.querySelectorAll(Selector);

    SetLength(FElementHandles, els.length);

    for i := 0 to els.length - 1 do
    begin
      el := TJSHTMLElement(els.item(i));
      FElementHandles[i] := el;
      BindEvent(Event, el);
    end;
  end;
end;

procedure TElementAction.BindEvent(AEvent: THTMLEvent;
  AElement: TJSElement);
begin
  case AEvent of
  heClick:
    AElement.addEventListener('click', FHandlePtr);
  heDblClick:
    AElement.addEventListener('dblclick', FHandlePtr);
  heMouseDown:
    AElement.addEventListener('mousedown', FHandlePtr);
  heMouseUp:
    AElement.addEventListener('mouseup', FHandlePtr);
  heMouseMove:
    AElement.addEventListener('mousemove', FHandlePtr);
  heMouseLeave:
    AElement.addEventListener('mouseleave', FHandlePtr);
  heMouseEnter:
    AElement.addEventListener('mouseenter', FHandlePtr);
  heKeyPress:
    AElement.addEventListener('keypress', FHandlePtr);
  heKeyDown:
    AElement.addEventListener('keydown', FHandlePtr);
  heKeyUp:
    AElement.addEventListener('keyup', FHandlePtr);
  heFocus:
    AElement.addEventListener('focus', FHandlePtr);
  heBlur:
    AElement.addEventListener('blur', FHandlePtr);
  heChange:
    AElement.addEventListener('change', FHandlePtr);
  heSelect:
    AElement.addEventListener('select', FHandlePtr);
  heInvalid:
    AElement.addEventListener('invalid', FHandlePtr);
  heInput:
    AElement.addEventListener('input', FHandlePtr);
  heTouchStart:
    AElement.addEventListener('touchstart', FHandlePtr);
  heTouchEnd:
    AElement.addEventListener('touchend', FHandlePtr);
  heTouchMove:
    AElement.addEventListener('touchmove', FHandlePtr);
  heTouchCancel:
    AElement.addEventListener('touchcancel', FHandlePtr);
  heWheel:
    AElement.addEventListener('wheel', FHandlePtr);
  heCustom:
    if CustomEvent <> '' then
      AElement.addEventListener(CustomEvent, FHandlePtr);
  end;
end;

constructor TElementAction.Create(Collection: TCollection);
begin
  inherited;
  FHandlePtr := @DoHandleEvent;
  FElementHandle := nil;
  FControlHandle := nil;
  FTag := 0;
  FName := 'Action' + IntToStr(Index);
  FEnabled := true;
  FStopPropagation := true;
  FPreventDefault := true;
end;

destructor TElementAction.Destroy;
begin
  UnBind;
  inherited;
end;

procedure TElementAction.DoAction(AEvent: TJSEvent; SrcElement, AElement: TJSElement);
var
  srcR,tgtR: TJSHTMLElementRecord;
  evR: TJSEventParameter;
begin
  case TargetAction of
    actSetHidden: AElement.setAttribute('hidden','true');
    actRemoveHidden: AElement.removeAttribute('hidden');
    actToggleHidden:
      begin
        if AElement.hasAttribute('hidden') then
          AElement.removeAttribute('hidden')
        else
          AElement.setAttribute('hidden','true');
      end;
    actSetReadOnly: AElement.setAttribute('readonly','true');
    actRemoveReadOnly: AElement.removeAttribute('readonly');
    actToggleReadOnly:
      begin
        if AElement.hasAttribute('readonly') then
          AElement.removeAttribute('readonly')
        else
          AElement.setAttribute('readonly','true');
      end;
    actSetDisabled: AElement.setAttribute('disabled','true');
    actRemoveDisabled: AElement.removeAttribute('disabled');
    actToggleDisabled:
      begin
        if AElement.hasAttribute('disabled') then
          AElement.removeAttribute('disabled')
        else
          AElement.setAttribute('disabled','true');
      end;
    actClear:
      begin
        asm
          AElement.value = '';
        end;
      end;
  end;

  if Assigned(OnUpdate) then
  begin
    srcR.element := TJSHTMLElement(SrcElement);
    tgtR.element := TJSHTMLElement(AElement);
    evR.JSEvent := AEvent;
    OnUpdate(Self, srcR, evR, tgtR);

    if Assigned( (Collection.Owner as TElementActionList).OnUpdate) then
       (Collection.Owner as TElementActionList).OnUpdate(Collection, Self, srcR, evR, tgtR);
  end;
end;

function TElementAction.DoHandleEvent(Event: TJSEvent): Boolean;
var
  ep: TJSEventParameter;
  el: TJSHTMLElementRecord;
  tgt: TJSHTMLElement;
  els: TJSNodeList;
  i: integer;

begin
  ep.JSEvent := Event;
  el.element := TJSHTMLElement(Event.target);

  if Enabled then
  begin
    if Assigned(  (Collection.Owner as TElementActionList).OnExecute) then
       (Collection.Owner as TElementActionList).OnExecute(Collection, Self, el, ep);

    if Assigned(OnExecute) then
      OnExecute(Self, el, ep);
  end;

  if (TargetAction <> actNone) then
  begin
    if Assigned(TargetControl) and Assigned(TargetControl.ElementHandle) then
      DoAction(ep.JSEvent, el.element, TargetControl.ElementHandle);

    if (TargetID <> '') then
    begin
      tgt := TJSHTMLElement(document.getElementById(TargetID));
      if Assigned(tgt) then
        DoAction(ep.JSEvent, el.element, tgt);
    end;

    if (TargetSelector <> '') then
    begin
      els := document.querySelectorAll(TargetSelector);

      if els.length > 0 then
      begin
        for i := 0 to els.length - 1 do
          DoAction(ep.JSEvent, el.element, TJSElement(els.item(i)));
      end;
    end;
  end;

  Result := true;

  if StopPropagation then
    Event.stopPropagation;
  if PreventDefault then
    Event.preventDefault;
end;

function TElementAction.GetDisplayName: string;
var
  s: string;
begin
  s := FName;

  if (s = '') then
  begin
    case Event of
      heClick: s := 'click';
      heDblClick: s := 'dblclick';
      heKeypress:  s := 'keypress';
      heKeydown:  s := 'keydown';
      heKeyup:  s := 'keyup';
      heMouseDown:  s := 'mousedown';
      heMouseMove:  s := 'mousemove';
      heMouseUp: s := 'mousemove';
      heMouseEnter:  s := 'mouseenter';
      heMouseLeave:  s := 'mouseleave';
      heBlur:  s := 'blur';
      heFocus:  s := 'focus';
      heChange:  s := 'change';
      heSelect:  s := 'select';
      heInvalid:  s := 'invalid';
      heInput:  s := 'input';
      heTouchStart: s := 'touchstart';
      heTouchEnd: s := 'touchend';
      heTouchCancel: s := 'touchcancel';
      heTouchMove: s := 'touchmove';
      heWheel: s := 'wheel';
    end;

    if ID <> '' then
      Result := s +'event_' + ID
    else
      Result := s +'event_' + IntToStr(Index);
  end
  else
    Result := s;
end;

procedure TElementAction.SetElementEvent(const Value: THTMLEvent);
var
  FLoading: boolean;
begin
  FLoading := Assigned(Collection) and Assigned(Collection.Owner) and (csLoading in (Collection.Owner as TElementActionList).ComponentState);

  if (FElementEvent <> Value) then
  begin
    if not FLoading then
      UnBind;
    FElementEvent := Value;
    if not FLoading then
      Bind;
  end;
end;

procedure TElementAction.SetElementID(const Value: TElementID);
begin
  if (FElementID <> Value) then
  begin
    FElementID := Value;
    if FElementID <> '' then
      FSelector := '';
  end;
end;

procedure TElementAction.SetName(const Value: string);
begin
  FName := Value;
end;

procedure TElementAction.SetSelector(const Value: string);
begin
  if (FSelector <> Value) then
  begin
    FSelector := Value;
    if FSelector <> '' then
      FElementID := '';
  end;
end;

procedure TElementAction.UnBind;
var
  i: integer;
begin
  if Assigned(FControlHandle) then
  begin
    UnBindEvent(Event, FControlHandle);
    FControlHandle := nil;
  end;

  if (FElementID <> '') and Assigned(FElementHandle) then
  begin
    UnBindEvent(Event, FElementHandle);
    FElementHandle := nil;
  end;

  if (FSelector <> '') and (Length(FElementHandles) > 0) then
  begin
    for i := 0 to Length(FElementHandles) - 1 do
    begin
      if Assigned(FElementHandles[i]) then
        UnBindEvent(Event, FElementHandles[i]);
      FElementHandles[i] := nil;
    end;
    SetLength(FELementHandles, 0);
  end;
end;

procedure TElementAction.UnBindEvent(AEvent: THTMLEvent; AElement: TJSElement);
begin
  if Assigned(AElement) then
  begin
    case Event of
    heClick:
      AElement.removeEventListener('click', FHandlePtr);
    heDblClick:
      AElement.removeEventListener('dblclick', FHandlePtr);
    heMouseDown:
      AElement.removeEventListener('mousedown', FHandlePtr);
    heMouseUp:
      AElement.removeEventListener('mouseup', FHandlePtr);
    heMouseMove:
      AElement.removeEventListener('mousemove', FHandlePtr);
    heMouseLeave:
      AElement.removeEventListener('mouseleave', FHandlePtr);
    heMouseEnter:
      AElement.removeEventListener('mouseenter', FHandlePtr);
    heKeyPress:
      AElement.removeEventListener('keypress', FHandlePtr);
    heKeyDown:
      AElement.removeEventListener('keydown', FHandlePtr);
    heKeyUp:
      AElement.removeEventListener('keyup', FHandlePtr);
    heFocus:
      AElement.removeEventListener('focus', FHandlePtr);
    heBlur:
      AElement.removeEventListener('blur', FHandlePtr);
    heChange:
      AElement.removeEventListener('change', FHandlePtr);
    heSelect:
      AElement.removeEventListener('select', FHandlePtr);
    heInvalid:
      AElement.removeEventListener('invalid', FHandlePtr);
    heInput:
      AElement.removeEventListener('input', FHandlePtr);
    heTouchStart:
      AElement.removeEventListener('touchstart', FHandlePtr);
    heTouchEnd:
      AElement.removeEventListener('touchend', FHandlePtr);
    heTouchMove:
      AElement.removeEventListener('touchmove', FHandlePtr);
    heTouchCancel:
      AElement.removeEventListener('touchcancel', FHandlePtr);
    heWheel:
      AElement.removeEventListener('wheel', FHandlePtr);

    heCustom:
        if CustomEvent <> '' then
          AElement.removeEventListener(CustomEvent, FHandlePtr);
    end;
  end;
end;

{ TElementActionList }

procedure TElementActionList.BeforeLoadDFMValues;
begin
  inherited;
  Loading;
end;

procedure TElementActionList.BindActions;
var
  i: integer;
begin
  if not Assigned(Actions) then
    Exit;

  //
  for i := 0 to Actions.Count - 1 do
  begin
    Actions[i].Bind;
  end;
end;

constructor TElementActionList.Create(AOwner: TComponent);
begin
  inherited;
  FActions := CreateActions;
end;

function TElementActionList.CreateActions: TElementActions;
begin
  Result := TElementActions.Create(Self);
end;

destructor TElementActionList.Destroy;
begin
  FActions.Free;
  inherited;
end;

function TElementActionList.FindByName(AName: string): TElementAction;
begin
  Result := Actions.GetActionByName(AName);
end;

function TElementActionList.GetAction(AName: string): TElementAction;
begin
  Result := Actions.GetActionByName(AName);
end;

function TElementActionList.GetActionByName(AName: string): TElementAction;
begin
  Result := Actions.GetActionByName(AName);
end;

function TElementActionList.GetByName(AName: string): TElementAction;
begin
  Result := Actions.GetByName(AName);
end;

procedure TElementActionList.Loaded;
begin
  inherited;

  BindActions;
end;

procedure TElementActionList.SetActions(const Value: TElementActions);
begin
  FActions.Assign(Value);
end;

procedure TElementActionList.UnBindActions;
var
  i: integer;
begin
  if not Assigned(Actions) then
    Exit;
  //
  for i := 0 to Actions.Count - 1 do
  begin
    Actions[i].UnBind;
  end;
end;


end.
