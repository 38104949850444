unit Datenmodul1;

interface


uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Modules, XData.Web.Client,
  XData.Web.Connection, XData.Model.Classes, WEBLib.ExtCtrls;


type
  TDataModule1 = class(TDataModule)
    xConnection: TXDataWebConnection;
    xClient: TXDataWebClient;
    procedure WebDataModuleCreate(Sender: TObject);
    procedure WebDataModuleDestroy(Sender: TObject);
    procedure xConnectionConnect(Sender: TObject);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    Trefferliste : TStringList;
  protected procedure LoadDFMValues; override; end;

var
  DataModule1: TDataModule1;

implementation

Uses WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.Grids,
  Globale_Prozeduren;


{%CLASSGROUP 'Vcl.Controls.TControl'}

{$R *.dfm}

procedure TDataModule1.WebDataModuleCreate(Sender: TObject);
begin
  try
    Trefferliste := TStringList.Create;
    Trefferliste.Clear;
    Datenbank_verbunden := False;
    xConnection.Connected := True;
  except on E: Exception do
    ShowMessage('Keine Datenbankverbindung: '+e.Message);
  end;
end;

procedure TDataModule1.WebDataModuleDestroy(Sender: TObject);
begin
  Trefferliste.Clear;
  FreeAndNil(Trefferliste);
end;

procedure TDataModule1.xConnectionConnect(Sender: TObject);
begin
  Datenbank_verbunden := True;
end;

procedure TDataModule1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  xConnection := TXDataWebConnection.Create(Self);
  xClient := TXDataWebClient.Create(Self);

  xConnection.BeforeLoadDFMValues;
  xClient.BeforeLoadDFMValues;
  try
    Name := 'DataModule1';
    SetEvent(Self, 'OnCreate', 'WebDataModuleCreate');
    SetEvent(Self, 'OnDestroy', 'WebDataModuleDestroy');
    Height := 372;
    Width := 464;
    xConnection.SetParentComponent(Self);
    xConnection.Name := 'xConnection';
    xConnection.URL := 'https://gloris.lysania.com:2001/grid';
    SetEvent(xConnection, Self, 'OnConnect', 'xConnectionConnect');
    xConnection.Left := 48;
    xConnection.Top := 40;
    xClient.SetParentComponent(Self);
    xClient.Name := 'xClient';
    xClient.Connection := xConnection;
    xClient.Left := 48;
    xClient.Top := 128;
  finally
    xConnection.AfterLoadDFMValues;
    xClient.AfterLoadDFMValues;
  end;
end;

end.
