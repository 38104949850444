program btw;

{$R *.dres}

uses
  Vcl.Forms,
  WEBLib.Forms,
  fo_Hauptformular in 'fo_Hauptformular.pas'{*.html},
  fo_Ueber_uns in 'fo_Ueber_uns.pas'{*.html},
  frame_webframe in 'frame_webframe.pas',
  Globale_Prozeduren in 'Globale_Prozeduren.pas',
  fo_AGB in 'fo_AGB.pas',
  fo_Anfahrt in 'fo_Anfahrt.pas'{*.html},
  fo_Impressum in 'fo_Impressum.pas'{*.html},
  fo_Datenschutz in 'fo_Datenschutz.pas'{*.html},
  fo_Messen in 'fo_Messen.pas'{*.html},
  Datenmodul1 in 'Datenmodul1.pas',
  fo_Produkte in 'fo_Produkte.pas'{*.html},
  fo_Produktdetails in 'fo_Produktdetails.pas'{*.html},
  fo_Produktanfrage in 'fo_Produktanfrage.pas'{*.html};

{$R *.res}

begin
  Application.Initialize;
  Application.MainFormOnTaskbar := True;
  Application.CreateFormDirect(TDataModule1, DataModule1);
  Application.CreateForm(TForm1, Form1);
  Application.CreateForm(Tfm_Ueber_uns, fm_Ueber_uns);
  Application.CreateForm(Tfm_AGB, fm_AGB);
  Application.CreateForm(Tfm_Anfahrt, fm_Anfahrt);
  Application.CreateForm(Tfm_Impressum, fm_Impressum);
  Application.CreateForm(Tfm_Datenschutz, fm_Datenschutz);
  Application.CreateForm(Tfm_Messen, fm_Messen);
  Application.CreateForm(Tfm_Produkte, fm_Produkte);
  Application.CreateForm(Tfm_Produktdetails, fm_Produktdetails);
  Application.CreateForm(Tfm_Produktanfrage, fm_Produktanfrage);
  Application.Run;
end.

