unit fo_Produkte;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.ExtCtrls, XData.Web.Client,
  XData.Web.Connection, WEBLib.Grids, Generics.Collections, WEBLib.WebCtrls;

type
  Tfm_Produkte = class(TForm)
    frm_webframe1: Tfrm_webframe;
    GRIDK: TResponsiveGrid;
    GRID_Treffer: TResponsiveGrid;
    WebButton1: TButton;
    WebButton2: TButton;
    WebButton3: TButton;
    WebButton4: TButton;
    agb: TLabel;
    anfahrt: TLabel;
    datenschutz: TLabel;
    impressum: TLabel;
    ueberuns: TLabel;
    home: TLabel;
    de: TImageControl;
    titel: TLinkLabel;
    navigation: THTMLDiv;
    produkte: TLabel;
    search: TEdit;
    uk: TImageControl;
    messen: TLabel;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure GRIDKItemCreated(Sender: TObject; Index: Integer);
    Procedure KategorieButtonClick (Sender : TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure WebButton3Click(Sender: TObject);
    procedure WebButton4Click(Sender: TObject);
    procedure GRID_TrefferItemClick(Sender: TObject; Index: Integer);
    procedure GRID_TrefferItemCreated(Sender: TObject; Index: Integer);
  private
    { Private-Deklarationen }
    akt_Seite : Integer;
    Procedure Lies_Kategorien_aus_Datenbank;
    Procedure Zeige_Suchergebnis_an;
    procedure Zeige_Produkte_einer_Kategorie;
    Procedure Lies_Treffer_pro_Seite;
    Procedure Zeige_Merkliste;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  fm_Produkte: Tfm_Produkte;

implementation

{$R *.dfm}

uses fo_Hauptformular, Globale_Prozeduren, Datenmodul1, fo_Produktdetails,
  fo_Produktanfrage;

{$R *.dfm}

procedure Tfm_Produkte.GRIDKItemCreated(Sender: TObject; Index: Integer);
Var btn : TButton;
    S   : String;
  X: Integer;
  Err: Integer;
begin
  btn := TButton.Create(Self);
  btn.ParentElement := GRIDK.Items[Index].ElementHandle;
  btn.Caption := 'Weitere Informationen';
  btn.OnClick := KategorieButtonClick;
  btn.Width := 300;
  btn.ElementFont := efCSS;
  btn.ElementClassName := 'btn btn-outline-info';
  btn.ElementHandle.removeAttribute('style');
  S := GRIDK.Items[Index].JSONElementValue['ID'];
  GRIDK.Items[Index].ElementHandle.className := 'card card-body';
  // Alles über den Rahmen hinausgehende wird versteckt.
  GRIDK.Items[Index].ElementHandle.style.setProperty('overflow','hidden');
  // fügt bei überlaufendem Text drei Punkt an...
  GRIDK.Items[Index].ElementHandle.style.setProperty('text-overflow','ellipsis');
  Val(S,X,Err);
  btn.Tag := X;
end;

procedure Tfm_Produkte.GRID_TrefferItemClick(Sender: TObject; Index: Integer);
VAR
  Child : Tfm_Produktdetails;

  procedure AfterCreate(AForm: TObject);
  begin
    (AForm as Tfm_Produktdetails).Produkt_ID := GRID_Treffer.Items[Index].Tag;
    (AForm as Tfm_Produktdetails).lbl_Artikelnr.Caption := GRID_Treffer.Items[Index].Tag.ToString;
    (AForm as Tfm_Produktdetails).Lies_Produktdaten;
  end;

  procedure AfterShowModal(AValue: TModalResult);
  begin
    if aValue = mrNo
      then
        Begin
          Zeige_Merkliste;
        End;
  end;

begin
  Child := Tfm_Produktdetails.CreateNew(@AfterCreate);
  Child.Popup := True;
  Child.Border := fbNone;
  Child.PopupOpacity := 0.2;
  Child.ShowModal (@AfterShowModal);
end;

procedure Tfm_Produkte.GRID_TrefferItemCreated(Sender: TObject; Index: Integer);
VAR S : String;
  X: Integer;
  Err: Integer;
begin
  // Produkt_ID als TAG speichern
  S := GRID_Treffer.Items[Index].JSONElementValue['Produkt_ID'];
  GRID_Treffer.Items[Index].ElementHandle.className := 'card card-body';
  // Alles über den Rahmen hinausgehende wird versteckt.
  GRID_Treffer.Items[Index].ElementHandle.style.setProperty('overflow','hidden');
  // fügt bei überlaufendem Text drei Punkt an...
  GRID_Treffer.Items[Index].ElementHandle.style.setProperty('text-overflow','ellipsis');
  VAL(S,X,Err);
  GRID_Treffer.Items[Index].Tag := X;
end;

procedure Tfm_Produkte.KategorieButtonClick(Sender: TObject);
Var el : TJSHTMLElement;
begin
  if Sender is TButton
    then
      Begin
        akt_Seite := 1;
        Kategorie := (Sender as TButton).Tag;
        Zeige_Produkte_einer_Kategorie;
      End;
end;

procedure Tfm_Produkte.Lies_Kategorien_aus_Datenbank;
  // Hier das JSON aus dem xData Server von allen Kategorien auslesen...
  Procedure OnResult (Response : TXDataClientResponse);
  begin
    GRIDK.Options.ItemTemplate := '<div class="card-category">(%Bezeichnung%) </div><br><img src="http://www.btw-werkzeug.de/grid-data/bilder/kategorien/k(%ID%).jpg" class="card image">';
    GRIDK.LoadFromJSON(Response.ResultAsObject,'value');
  end;

begin
  GRIDK.Visible := True;
  GRID_Treffer.Visible := False;
  WebButton1.Visible := False;
  WebButton2.Visible := False;
  WebButton3.Visible := False;
  WebButton4.Visible := False;
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Alle_Kategorien',[],@OnResult);
end;

procedure Tfm_Produkte.Lies_Treffer_pro_Seite;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
    Treffer_pro_Seite := Integer(TJSObject(Response.Result)['value']);
    if Treffer_pro_Seite = 0
      then
        Treffer_pro_Seite := 30;
  end;
begin
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Anzahl_Treffer',[],@OnResult);
end;

procedure Tfm_Produkte.WebButton1Click(Sender: TObject);
begin
  // Vorherige Seite aufrufen
  if akt_Seite > 1
    then
      Begin
        Dec(akt_Seite);
        Zeige_Suchergebnis_an;
      End;
end;

procedure Tfm_Produkte.WebButton2Click(Sender: TObject);
begin
  // Naechste Seite aufrufen...
  if GRID_TReffer.Items.Count = Treffer_pro_Seite
    then
      Begin
        Inc(akt_Seite);
        Zeige_Suchergebnis_an;
      End;
end;

procedure Tfm_Produkte.WebButton3Click(Sender: TObject);
begin
  // Vorherige Produkte einer Kategorie
  if akt_Seite > 1
    then
      Begin
        Dec(akt_Seite);
        Zeige_Produkte_einer_Kategorie;
      End;
end;

procedure Tfm_Produkte.WebButton4Click(Sender: TObject);
begin
  // Naechste Produkte einer Kategorie
  if GRID_TReffer.Items.Count = Treffer_pro_Seite
    then
      Begin
        Inc(akt_Seite);
        Zeige_Produkte_einer_Kategorie;
      End;
end;

procedure Tfm_Produkte.WebFormCreate(Sender: TObject);
begin
  GRIDK.Options.ItemBorderColor := clSilver;
  GRIDK.Options.ItemHoverColor := $EFFFFF;
  GRIDK.Options.ItemHoverBorderColor := clGray;
  GRIDK.Options.ItemSelectedColor := clSilver;
  GRIDK.Options.ItemSelectedBorderColor := clAqua;
  GRIDK.Options.ItemSelectedTextColor := clGray;
  GRIDK.Items.Clear;
  GRIDK.Visible := False;

  GRID_Treffer.Options.ItemBorderColor := clSilver;
  GRID_Treffer.Options.ItemHoverColor := $EFFFFF;
  GRID_Treffer.Options.ItemHoverBorderColor := clGray;
  GRID_Treffer.Options.ItemSelectedColor := clSilver;
  GRID_Treffer.Options.ItemSelectedBorderColor := clAqua;
  GRID_Treffer.Options.ItemSelectedTextColor := clGray;
  GRID_Treffer.Items.Clear;
  GRID_Treffer.Visible := False;

  // Bei einer Auflösung des Bildschirms > FullHD soll die Übersicht
  // auf jeden Fall einspaltig bleiben...
  if Window.screen.width > 1920
    then
      GRID_Treffer.Options.ItemWidthMin := 1000
    else
      GRID_Treffer.Options.ItemWidthMin := 700;

  akt_Seite := 1;
  Kategorie := 0;

  frm_webframe1.Menupunkte_umbenennen;

  if Suchbegriff = ''
    then
      Lies_Kategorien_aus_Datenbank
    else
      Zeige_Suchergebnis_an;

end;

procedure Tfm_Produkte.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  case Sprache of
    1 :  frm_webframe1.lbl_Titel.Caption :=  'Produkte';
    2 :  frm_webframe1.lbl_Titel.Caption :=  'Products';
  end;
  // Hier wird der angeklickte Menupunkt aktiv gesetzt
  el := document.getElementById('produkte');
  el['class'] := 'active';
end;

procedure Tfm_Produkte.Zeige_Merkliste;
begin
  frm_webframe1.Zeige_Formular(tfm_Produktanfrage);
end;

procedure Tfm_Produkte.Zeige_Produkte_einer_Kategorie;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
//    GRID_Treffer.Options.ItemTemplate := '<table style="width: 90%;" ><tbody><tr><td style="width:320px"><img src="http://www.btw-werkzeug.de/grid-data/bilder/produkte/(%Dateiname%)">'+
//                                         ' <br><br><b>Art.Nr.: (%Artikelnr%)</b></td><td valign=top><b>(%Bezeichnung%)</b>(%Beschreibung%)</td></tr></tbody></table>';
    GRID_Treffer.Options.ItemTemplate := '<table style="width: 90%;" ><tbody><tr><td style="width:340px"><img src="http://www.btw-werkzeug.de/grid-data/bilder/produkte/(%Dateiname%)">'+
                                         ' <br><br><b>Art.Nr.: (%Artikelnr%)</b></td><td valign=top><b>(%Bezeichnung%)</b>(%Beschreibung%)</td></tr></tbody></table>';

    GRID_Treffer.LoadFromJSON(Response.ResultAsObject,'value');
  end;
begin
  GRIDK.Items.Clear;
  GRIDK.Visible := False;
  // Rahmen vom GRIDK fügt Scrollbar hinzu, wenn GRID Elemente größer werden als das GRID
  GRIDK.ElementHandle.style.setProperty('overflow','auto');
  GRID_Treffer.Visible := True;
  GRID_Treffer.Items.Clear;
  WebButton1.Visible := False;   // Button nach Suche
  WebButton2.Visible := False;   // Button nach Suche
  WebButton3.Visible := True;    // Button für Kategorie
  WebButton4.Visible := True;    // Button für Kategorie

  WebButton3.Visible := akt_Seite > 1;

  Suchbegriff := '';
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Naechste_Bild_IDs_einer_Kategorie',[Sprache,Kategorie,akt_Seite],@OnResult);
end;

procedure Tfm_Produkte.Zeige_Suchergebnis_an;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
//    GRID_Treffer.Options.ItemTemplate := '<table style="width: 90%;" ><tbody><tr><td style="width:320px"><img src="http://www.btw-werkzeug.de/grid-data/bilder/produkte/(%Dateiname%)"'+
//                                         ' width="300" height="200"><br><br><b>Art.Nr.: (%Artikelnr%)</b></td><td valign=top><b>(%Bezeichnung%)</b>(%Beschreibung%)</td></tr></tbody></table>';
    GRID_Treffer.Options.ItemTemplate := '<table style="width: 90%;" ><tbody><tr><td style="width:320px"><img src="http://www.btw-werkzeug.de/grid-data/bilder/produkte/(%Dateiname%)">'+
                                         ' <br><br><b>Art.Nr.: (%Artikelnr%)</b></td><td valign=top><b>(%Bezeichnung%)</b>(%Beschreibung%)</td></tr></tbody></table>';

    GRID_Treffer.LoadFromJSON(Response.ResultAsObject,'value');
  end;
begin
  GRIDK.Visible := False;
  GRID_Treffer.Visible := True;
  GRID_Treffer.Items.Clear;
  // Rahmen vom GRID_Treffer fügt Scrollbar hinzu, wenn GRID Elemente größer werden als das GRID
  GRID_Treffer.ElementHandle.style.setProperty('overflow','auto');
  WebButton1.Visible := True;     // Button nach Suche
  WebButton2.Visible := True;     // Button nach Suche
  WebButton3.Visible := False;    // Button für Kategorie
  WebButton4.Visible := False;    // Button für Kategorie

  WebButton1.Visible := akt_Seite > 1;
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Naechste_Bild_IDs_eines_Suchbegriffs',[Sprache,Suchbegriff,akt_Seite],@OnResult);
  // Hier das JSON aus dem xData Server von allen Kategorien auslesen...
end;

procedure Tfm_Produkte.LoadDFMValues;
begin
  inherited LoadDFMValues;

  agb := TLabel.Create('agb');
  anfahrt := TLabel.Create('anfahrt');
  datenschutz := TLabel.Create('datenschutz');
  impressum := TLabel.Create('impressum');
  ueberuns := TLabel.Create('ueberuns');
  home := TLabel.Create('home');
  de := TImageControl.Create('de');
  titel := TLinkLabel.Create('titel');
  produkte := TLabel.Create('produkte');
  uk := TImageControl.Create('uk');
  messen := TLabel.Create('messen');
  frm_webframe1 := Tfrm_webframe.Create(Self);
  GRIDK := TResponsiveGrid.Create('webform');
  GRID_Treffer := TResponsiveGrid.Create('webform');
  WebButton1 := TButton.Create('prod_rueck');
  WebButton2 := TButton.Create('prod_vor');
  WebButton3 := TButton.Create('kat_rueck');
  WebButton4 := TButton.Create('kat_vor');
  navigation := THTMLDiv.Create('navigation');
  search := TEdit.Create('search');

  frm_webframe1.LoadFromForm;
  agb.BeforeLoadDFMValues;
  anfahrt.BeforeLoadDFMValues;
  datenschutz.BeforeLoadDFMValues;
  impressum.BeforeLoadDFMValues;
  ueberuns.BeforeLoadDFMValues;
  home.BeforeLoadDFMValues;
  de.BeforeLoadDFMValues;
  titel.BeforeLoadDFMValues;
  produkte.BeforeLoadDFMValues;
  uk.BeforeLoadDFMValues;
  messen.BeforeLoadDFMValues;
  frm_webframe1.BeforeLoadDFMValues;
  frm_webframe1.WebElementActionList1.BeforeLoadDFMValues;
  GRIDK.BeforeLoadDFMValues;
  GRID_Treffer.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  WebButton3.BeforeLoadDFMValues;
  WebButton4.BeforeLoadDFMValues;
  navigation.BeforeLoadDFMValues;
  search.BeforeLoadDFMValues;
  try
    Name := 'fm_Produkte';
    Width := 1472;
    Height := 867;
    Caption := 'webform';
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    FormContainer := 'webform';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    agb.SetParentComponent(Self);
    agb.Name := 'agb';
    agb.Left := 1212;
    agb.Top := 0;
    agb.Width := 20;
    agb.Height := 15;
    agb.Caption := 'agb';
    agb.ElementFont := efCSS;
    agb.ElementPosition := epIgnore;
    agb.HeightStyle := ssAuto;
    agb.HeightPercent := 100.000000000000000000;
    agb.WidthStyle := ssAuto;
    agb.WidthPercent := 100.000000000000000000;
    anfahrt.SetParentComponent(Self);
    anfahrt.Name := 'anfahrt';
    anfahrt.Left := 1313;
    anfahrt.Top := 0;
    anfahrt.Width := 38;
    anfahrt.Height := 15;
    anfahrt.Caption := 'anfahrt';
    anfahrt.ElementFont := efCSS;
    anfahrt.ElementPosition := epIgnore;
    anfahrt.HeightStyle := ssAuto;
    anfahrt.HeightPercent := 100.000000000000000000;
    anfahrt.WidthStyle := ssAuto;
    anfahrt.WidthPercent := 100.000000000000000000;
    datenschutz.SetParentComponent(Self);
    datenschutz.Name := 'datenschutz';
    datenschutz.Left := 1212;
    datenschutz.Top := 30;
    datenschutz.Width := 64;
    datenschutz.Height := 15;
    datenschutz.Caption := 'datenschutz';
    datenschutz.ElementFont := efCSS;
    datenschutz.ElementPosition := epIgnore;
    datenschutz.HeightStyle := ssAuto;
    datenschutz.HeightPercent := 100.000000000000000000;
    datenschutz.WidthStyle := ssAuto;
    datenschutz.WidthPercent := 100.000000000000000000;
    impressum.SetParentComponent(Self);
    impressum.Name := 'impressum';
    impressum.Left := 1313;
    impressum.Top := 30;
    impressum.Width := 59;
    impressum.Height := 15;
    impressum.Caption := 'impressum';
    impressum.ElementFont := efCSS;
    impressum.ElementPosition := epIgnore;
    impressum.HeightStyle := ssAuto;
    impressum.HeightPercent := 100.000000000000000000;
    impressum.WidthStyle := ssAuto;
    impressum.WidthPercent := 100.000000000000000000;
    ueberuns.SetParentComponent(Self);
    ueberuns.Name := 'ueberuns';
    ueberuns.Left := 1212;
    ueberuns.Top := 60;
    ueberuns.Width := 49;
    ueberuns.Height := 15;
    ueberuns.Caption := 'ueberuns';
    ueberuns.ElementFont := efCSS;
    ueberuns.ElementPosition := epIgnore;
    ueberuns.HeightStyle := ssAuto;
    ueberuns.HeightPercent := 100.000000000000000000;
    ueberuns.WidthStyle := ssAuto;
    ueberuns.WidthPercent := 100.000000000000000000;
    home.SetParentComponent(Self);
    home.Name := 'home';
    home.Left := 1313;
    home.Top := 60;
    home.Width := 31;
    home.Height := 15;
    home.Caption := 'home';
    home.ElementFont := efCSS;
    home.ElementPosition := epIgnore;
    home.HeightStyle := ssAuto;
    home.HeightPercent := 100.000000000000000000;
    home.WidthStyle := ssAuto;
    home.WidthPercent := 100.000000000000000000;
    de.SetParentComponent(Self);
    de.Name := 'de';
    de.Left := 1313;
    de.Top := 90;
    de.Width := 96;
    de.Height := 25;
    de.HeightStyle := ssAuto;
    de.WidthStyle := ssAuto;
    de.HeightPercent := 100.000000000000000000;
    de.WidthPercent := 100.000000000000000000;
    de.ChildOrder := 13;
    de.ElementFont := efCSS;
    de.ElementPosition := epIgnore;
    titel.SetParentComponent(Self);
    titel.Name := 'titel';
    titel.Left := 1313;
    titel.Top := 120;
    titel.Width := 20;
    titel.Height := 15;
    titel.ElementFont := efCSS;
    titel.HeightStyle := ssAuto;
    titel.WidthStyle := ssAuto;
    titel.HeightPercent := 100.000000000000000000;
    titel.WidthPercent := 100.000000000000000000;
    titel.Caption := 'titel';
    produkte.SetParentComponent(Self);
    produkte.Name := 'produkte';
    produkte.Left := 1212;
    produkte.Top := 180;
    produkte.Width := 48;
    produkte.Height := 15;
    produkte.Caption := 'produkte';
    produkte.ElementFont := efCSS;
    produkte.ElementPosition := epIgnore;
    produkte.HeightStyle := ssAuto;
    produkte.HeightPercent := 100.000000000000000000;
    produkte.WidthStyle := ssAuto;
    produkte.WidthPercent := 100.000000000000000000;
    uk.SetParentComponent(Self);
    uk.Name := 'uk';
    uk.Left := 1313;
    uk.Top := 210;
    uk.Width := 96;
    uk.Height := 25;
    uk.HeightStyle := ssAuto;
    uk.WidthStyle := ssAuto;
    uk.HeightPercent := 100.000000000000000000;
    uk.WidthPercent := 100.000000000000000000;
    uk.ChildOrder := 18;
    uk.ElementFont := efCSS;
    uk.ElementPosition := epIgnore;
    messen.SetParentComponent(Self);
    messen.Name := 'messen';
    messen.Left := 1313;
    messen.Top := 240;
    messen.Width := 40;
    messen.Height := 15;
    messen.Caption := 'messen';
    messen.ElementFont := efCSS;
    messen.ElementPosition := epIgnore;
    messen.HeightStyle := ssAuto;
    messen.HeightPercent := 100.000000000000000000;
    messen.WidthStyle := ssAuto;
    messen.WidthPercent := 100.000000000000000000;
    frm_webframe1.SetParentComponent(Self);
    frm_webframe1.Name := 'frm_webframe1';
    frm_webframe1.Left := 1184;
    frm_webframe1.Top := 384;
    frm_webframe1.Width := 225;
    frm_webframe1.Height := 201;
    frm_webframe1.Color := clBtnFace;
    frm_webframe1.ParentBackground := False;
    frm_webframe1.ParentColor := False;
    frm_webframe1.TabOrder := 0;
    frm_webframe1.Visible := False;
    frm_webframe1.WebElementActionList1.Actions.Clear;
    with frm_webframe1.WebElementActionList1.Actions.Add do
    begin
      Event := heKeypress;
      ID := 'search';
      Name := 'act_Suchestarten';
      PreventDefault := False;
    end;
    GRIDK.SetParentComponent(Self);
    GRIDK.Name := 'GRIDK';
    GRIDK.Left := 23;
    GRIDK.Top := 24;
    GRIDK.Width := 1094;
    GRIDK.Height := 385;
    GRIDK.ElementClassName := 'respgrid';
    GRIDK.HeightStyle := ssAuto;
    GRIDK.WidthPercent := 90.000000000000000000;
    GRIDK.ChildOrder := 1;
    GRIDK.ElementFont := efCSS;
    GRIDK.ItemIndex := 0;
    GRIDK.Options.ItemClassName := 'respitemsmall';
    GRIDK.Options.ItemGap := 20;
    GRIDK.Options.ItemHeight := 300;
    GRIDK.Options.ItemPadding := 20;
    GRIDK.Options.ItemWidthMin := 300;
    GRIDK.Options.ScrollVertical := False;
    SetEvent(GRIDK, Self, 'OnItemCreated', 'GRIDKItemCreated');
    GRID_Treffer.SetParentComponent(Self);
    GRID_Treffer.Name := 'GRID_Treffer';
    GRID_Treffer.Left := 23;
    GRID_Treffer.Top := 432;
    GRID_Treffer.Width := 1094;
    GRID_Treffer.Height := 361;
    GRID_Treffer.ElementClassName := 'respgrid';
    GRID_Treffer.HeightStyle := ssAuto;
    GRID_Treffer.ChildOrder := 2;
    GRID_Treffer.ElementFont := efCSS;
    GRID_Treffer.ItemIndex := 0;
    GRID_Treffer.Options.ItemClassName := 'respitemsmallest';
    GRID_Treffer.Options.ItemGap := 20;
    GRID_Treffer.Options.ItemHeight := 300;
    GRID_Treffer.Options.ItemPadding := 20;
    GRID_Treffer.Options.ItemWidthMin := 700;
    GRID_Treffer.Visible := False;
    SetEvent(GRID_Treffer, Self, 'OnItemCreated', 'GRID_TrefferItemCreated');
    SetEvent(GRID_Treffer, Self, 'OnItemClick', 'GRID_TrefferItemClick');
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 1152;
    WebButton1.Top := 100;
    WebButton1.Width := 113;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Vorherige Seite';
    WebButton1.ChildOrder := 3;
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthStyle := ssAuto;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 1152;
    WebButton2.Top := 142;
    WebButton2.Width := 113;
    WebButton2.Height := 25;
    WebButton2.Caption := 'N'#228'chste Seite';
    WebButton2.ChildOrder := 3;
    WebButton2.ElementClassName := 'btn btn-primary';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.WidthStyle := ssAuto;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    WebButton3.SetParentComponent(Self);
    WebButton3.Name := 'WebButton3';
    WebButton3.Left := 1152;
    WebButton3.Top := 226;
    WebButton3.Width := 113;
    WebButton3.Height := 25;
    WebButton3.Caption := 'Vorherige Seite';
    WebButton3.ChildOrder := 3;
    WebButton3.ElementClassName := 'btn btn-primary';
    WebButton3.ElementFont := efCSS;
    WebButton3.HeightStyle := ssAuto;
    WebButton3.HeightPercent := 100.000000000000000000;
    WebButton3.WidthStyle := ssAuto;
    WebButton3.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton3, Self, 'OnClick', 'WebButton3Click');
    WebButton4.SetParentComponent(Self);
    WebButton4.Name := 'WebButton4';
    WebButton4.Left := 1152;
    WebButton4.Top := 270;
    WebButton4.Width := 113;
    WebButton4.Height := 25;
    WebButton4.Caption := 'N'#228'chste Seite';
    WebButton4.ChildOrder := 3;
    WebButton4.ElementClassName := 'btn btn-primary';
    WebButton4.ElementFont := efCSS;
    WebButton4.HeightStyle := ssAuto;
    WebButton4.HeightPercent := 100.000000000000000000;
    WebButton4.WidthStyle := ssAuto;
    WebButton4.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton4, Self, 'OnClick', 'WebButton4Click');
    navigation.SetParentComponent(Self);
    navigation.Name := 'navigation';
    navigation.Left := 1313;
    navigation.Top := 150;
    navigation.Width := 96;
    navigation.Height := 25;
    navigation.HeightStyle := ssAuto;
    navigation.WidthStyle := ssAuto;
    navigation.ChildOrder := 15;
    navigation.ElementPosition := epIgnore;
    navigation.ElementFont := efCSS;
    navigation.Role := '';
    search.SetParentComponent(Self);
    search.Name := 'search';
    search.Left := 1313;
    search.Top := 180;
    search.Width := 96;
    search.Height := 25;
    search.ChildOrder := 17;
    search.ElementFont := efCSS;
    search.ElementPosition := epIgnore;
    search.HeightStyle := ssAuto;
    search.HeightPercent := 100.000000000000000000;
    search.WidthStyle := ssAuto;
    search.WidthPercent := 100.000000000000000000;
  finally
    agb.AfterLoadDFMValues;
    anfahrt.AfterLoadDFMValues;
    datenschutz.AfterLoadDFMValues;
    impressum.AfterLoadDFMValues;
    ueberuns.AfterLoadDFMValues;
    home.AfterLoadDFMValues;
    de.AfterLoadDFMValues;
    titel.AfterLoadDFMValues;
    produkte.AfterLoadDFMValues;
    uk.AfterLoadDFMValues;
    messen.AfterLoadDFMValues;
    frm_webframe1.AfterLoadDFMValues;
    frm_webframe1.WebElementActionList1.AfterLoadDFMValues;
    GRIDK.AfterLoadDFMValues;
    GRID_Treffer.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    WebButton3.AfterLoadDFMValues;
    WebButton4.AfterLoadDFMValues;
    navigation.AfterLoadDFMValues;
    search.AfterLoadDFMValues;
  end;
end;

end.
