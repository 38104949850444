unit fo_Hauptformular;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.ExtCtrls, XData.Web.Client,
  XData.Web.Connection;

type
  TForm1 = class(TForm)
    frm_webframe1: Tfrm_webframe;
    WebHTMLContainer1: THTMLContainer;
    WebTimer1: TTimer;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebTimer1Timer(Sender: TObject);
  private
    { Private-Deklarationen }
    Procedure Lies_HTML_aus_Datenbank;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

uses Datenmodul1, Globale_Prozeduren, fo_Produkte;

procedure TForm1.Lies_HTML_aus_Datenbank;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
    WebHTMLContainer1.HTML.Text := String(TJSObject(Response.Result)['value']);
  end;

begin
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Statische_Website',[1,Sprache],@OnResult);
end;

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  WebTimer1.Enabled := true;
end;

procedure TForm1.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  case Sprache of
    1 :  frm_webframe1.lbl_Titel.Caption :=  'Home';
    2 :  frm_webframe1.lbl_Titel.Caption :=  'Home';
  end;

  el := document.getElementById('home');
  el['class'] := 'active';
end;

procedure TForm1.WebTimer1Timer(Sender: TObject);
begin
  Webtimer1.Enabled := False;
  if Datenbank_verbunden = TRUE
    then
      Begin
        Lies_HTML_aus_Datenbank;
      End
    else
      Webtimer1.Enabled := True;
end;

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  frm_webframe1 := Tfrm_webframe.Create(Self);
  WebHTMLContainer1 := THTMLContainer.Create('webform');
  WebTimer1 := TTimer.Create(Self);

  frm_webframe1.LoadFromForm;
  frm_webframe1.BeforeLoadDFMValues;
  frm_webframe1.lbl_eng.BeforeLoadDFMValues;
  frm_webframe1.WebElementActionList1.BeforeLoadDFMValues;
  WebHTMLContainer1.BeforeLoadDFMValues;
  WebTimer1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 930;
    Height := 679;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    FormContainer := 'webform';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    frm_webframe1.SetParentComponent(Self);
    frm_webframe1.Name := 'frm_webframe1';
    frm_webframe1.Left := 336;
    frm_webframe1.Top := 40;
    frm_webframe1.Width := 313;
    frm_webframe1.Height := 273;
    frm_webframe1.Color := clBtnFace;
    frm_webframe1.ParentBackground := False;
    frm_webframe1.ParentColor := False;
    frm_webframe1.TabOrder := 0;
    frm_webframe1.Visible := False;
    frm_webframe1.WebElementActionList1.Actions.Clear;
    with frm_webframe1.WebElementActionList1.Actions.Add do
    begin
      Event := heKeypress;
      ID := 'search';
      Name := 'act_Suchestarten';
      PreventDefault := False;
    end;
    WebHTMLContainer1.SetParentComponent(Self);
    WebHTMLContainer1.Name := 'WebHTMLContainer1';
    WebHTMLContainer1.Left := 49;
    WebHTMLContainer1.Top := 66;
    WebHTMLContainer1.Width := 265;
    WebHTMLContainer1.Height := 66;
    WebHTMLContainer1.ElementClassName := 'form-control';
    WebHTMLContainer1.ChildOrder := 4;
    WebHTMLContainer1.ElementFont := efCSS;
    WebTimer1.SetParentComponent(Self);
    WebTimer1.Name := 'WebTimer1';
    WebTimer1.Enabled := False;
    WebTimer1.Interval := 50;
    SetEvent(WebTimer1, Self, 'OnTimer', 'WebTimer1Timer');
    WebTimer1.Left := 448;
    WebTimer1.Top := 328;
  finally
    frm_webframe1.AfterLoadDFMValues;
    frm_webframe1.lbl_eng.AfterLoadDFMValues;
    frm_webframe1.WebElementActionList1.AfterLoadDFMValues;
    WebHTMLContainer1.AfterLoadDFMValues;
    WebTimer1.AfterLoadDFMValues;
  end;
end;

end.