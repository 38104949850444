unit fo_AGB;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.ExtCtrls, XData.Web.Client,
  XData.Web.Connection;

type
  Tfm_AGB = class(TForm)
    frm_webframe1: Tfrm_webframe;
    WebHTMLContainer1: THTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
    Procedure Lies_HTML_aus_Datenbank;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  fm_AGB: Tfm_AGB;

implementation

{$R *.dfm}

uses fo_Hauptformular, Globale_Prozeduren, Datenmodul1;

{$R *.dfm}

procedure Tfm_AGB.Lies_HTML_aus_Datenbank;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
    WebHTMLContainer1.HTML.Text := String(TJSObject(Response.Result)['value']);
  end;

begin
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Statische_Website',[6,Sprache],@OnResult);
end;


procedure Tfm_AGB.WebFormCreate(Sender: TObject);
begin
  frm_webframe1.Menupunkte_umbenennen;
  Lies_HTML_aus_Datenbank;
end;

procedure Tfm_AGB.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  case Sprache of
    1 :  frm_webframe1.lbl_Titel.Caption :=  'AGB';
    2 :  frm_webframe1.lbl_Titel.Caption :=  'Conditions';
  end;
  // Hier wird der angeklickte Menupunkt aktiv gesetzt
  el := document.getElementById('agb');
  el['class'] := 'active';
end;


procedure Tfm_AGB.LoadDFMValues;
begin
  inherited LoadDFMValues;

  frm_webframe1 := Tfrm_webframe.Create(Self);
  WebHTMLContainer1 := THTMLContainer.Create('webform');

  frm_webframe1.LoadFromForm;
  frm_webframe1.BeforeLoadDFMValues;
  WebHTMLContainer1.BeforeLoadDFMValues;
  try
    Name := 'fm_AGB';
    Width := 640;
    Height := 480;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    FormContainer := 'webform';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    frm_webframe1.SetParentComponent(Self);
    frm_webframe1.Name := 'frm_webframe1';
    frm_webframe1.Left := 248;
    frm_webframe1.Top := 32;
    frm_webframe1.Width := 478;
    frm_webframe1.Height := 294;
    frm_webframe1.TabOrder := 0;
    WebHTMLContainer1.SetParentComponent(Self);
    WebHTMLContainer1.Name := 'WebHTMLContainer1';
    WebHTMLContainer1.Left := 49;
    WebHTMLContainer1.Top := 66;
    WebHTMLContainer1.Width := 265;
    WebHTMLContainer1.Height := 66;
    WebHTMLContainer1.ElementClassName := 'form-control';
    WebHTMLContainer1.WidthStyle := ssPercent;
    WebHTMLContainer1.WidthPercent := 70.000000000000000000;
    WebHTMLContainer1.ChildOrder := 4;
    WebHTMLContainer1.ElementFont := efCSS;
  finally
    frm_webframe1.AfterLoadDFMValues;
    WebHTMLContainer1.AfterLoadDFMValues;
  end;
end;

end.
