unit fo_Dummy;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.ExtCtrls, XData.Web.Client,
  XData.Web.Connection;

type
  Tfm_Dummy = class(TWebForm)
    frm_webframe1: Tfrm_webframe;
    WebHTMLContainer1: TWebHTMLContainer;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
  private
    { Private-Deklarationen }
    Procedure Lies_HTML_aus_Datenbank;
  public
    { Public-Deklarationen }
  end;

var
  fm_Dummy: Tfm_Dummy;

implementation

{$R *.dfm}

uses fo_Hauptformular, Globale_Prozeduren, Datenmodul1;


procedure Tfm_Dummy.Lies_HTML_aus_Datenbank;
  Procedure OnResult (Response : TXDataClientResponse);
  begin
    WebHTMLContainer1.HTML.Text := String(TJSObject(Response.Result)['value']);
  end;

begin
  DataModule1.xClient.RawInvoke('IBTW_AbfragenService.Statische_Website',[4,1],@OnResult);
end;

procedure Tfm_Dummy.WebFormCreate(Sender: TObject);
begin
  Lies_HTML_aus_Datenbank;
end;

procedure Tfm_Dummy.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  frm_webframe1.lbl_Titel.Caption := 'Messen';
  el := document.getElementById('produkte');
  el['class'] := 'active';
end;

end.