unit fo_Produktanfrage;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe, WEBLib.ExtCtrls, XData.Web.Client,
  XData.Web.Connection, Generics.Collections;

type
  Tfm_Produktanfrage = class(TForm)
    frm_webframe1: Tfrm_webframe;
    ed_Name: TEdit;
    ed_Firma: TEdit;
    ed_Mailadresse: TEdit;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    ed_Telefon: TEdit;
    WebLabel4: TLabel;
    WebButton1: TButton;
    WebLabel5: TLabel;
    HTML: THTMLContainer;
    WebButton2: TButton;
    Dlg: TMessageDlg;
    procedure WebFormShow(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebButton2Click(Sender: TObject);
    procedure DlgClose(Sender: TObject);
  private
    { Private-Deklarationen }
    Procedure Erstelle_Anfragememo;
    Function  Eingaben_vollstaendig : Boolean;
    Procedure Speicher_Anfrage;
  public
    { Public-Deklarationen }
  protected procedure LoadDFMValues; override; end;

var
  fm_Produktanfrage: Tfm_Produktanfrage;

implementation

{$R *.dfm}

uses fo_Hauptformular, Globale_Prozeduren, Datenmodul1, fo_Produkte;

function Tfm_Produktanfrage.Eingaben_vollstaendig: Boolean;
var
  S: String;
begin
  Result := False;
  if ed_Name.Text = ''
    then
      Begin
        MessageDlg('Bitte geben Sie einen Namen ein.',mtError,[mbOK]);
        Exit;
      End;
  if ed_Firma.Text = ''
    then
      Begin
        MessageDlg('Bitte geben Sie Ihren Firmennamen ein.',mtError,[mbOK]);
        Exit;
      End;
  if ed_Mailadresse.Text = ''
    then
      Begin
        MessageDlg('Bitte geben Sie Ihre Mailadresse ein.',mtError,[mbOK]);
        Exit;
      End;
  S := ed_Mailadresse.Text;
  if ((Pos('@',S) = 0) OR (Pos('.',S) = 0))
    Then
      Begin
        MessageDlg('Die Mailadresse ist ungültig.',mtError,[mbOK]);
        Exit;
      End;
  if HTML.HTML.Text = ''
    then
      Begin
        MessageDlg('Die Produktliste ist leer.',mtError,[mbOK]);
        Exit;
      End;

  Result := True;
end;

procedure Tfm_Produktanfrage.Erstelle_Anfragememo;
var
  I: Integer;
  S: string;
begin
  HTML.HTML.Clear;
  HTML.HTML.Add ('Preisanfrage für Produkte: <br><br>');
  HTML.HTML.Add ('');
  for I := 0 to Length(Merkliste) -1 do
    Begin
      S := Merkliste[I].Menge.ToString+' x '+
           Merkliste[I].Artikelnr+' => [' +
           Merkliste[I].Preis +' EUR] => ' +
           Merkliste[I].Bezeichnung + '<br>';
      HTML.HTML.Add(S);
      HTML.HTML.Add('');
    End;
//  m_Produkte.AutoSize := True;
end;


procedure Tfm_Produktanfrage.Speicher_Anfrage;
Var obj  : TJSObject;
begin
  obj := TJSObject.New;
  obj['Name'] := ed_Name.Text;
  obj['Firma'] := ed_Firma.Text;
  obj['Mailadresse'] := ed_Mailadresse.Text;
  obj['Telefon'] := ed_Telefon.Text;
  obj['Bemerkung'] := Entferne_Breaks(HTML.HTML.Text);
  DataModule1.xClient.RawInvoke ('IBTW_AbfragenService.Speicher_Produktanfrage',[obj],'post');
end;

procedure Tfm_Produktanfrage.WebButton1Click(Sender: TObject);
begin
  if not Eingaben_vollstaendig
    then
      Exit;
  Speicher_Anfrage;
  SetLength(Merkliste,0);
  HTML.HTML.Clear;
  case Sprache of
    1 : DLG.ShowDialog('Vielen Dank für Ihre Anfrage. Wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen.', Weblib.Dialogs.mtInformation,[mbOK]);
    2 : DLG.ShowDialog('Thank you very much for your inquiry. We will come back to you as soon as possible.', Weblib.Dialogs.mtInformation,[mbOK]);
  end;

end;

procedure Tfm_Produktanfrage.WebButton2Click(Sender: TObject);
begin
  ed_Name.Text         := 'Thomas Reppel';
  ed_Firma.Text        := 'GRID-DATA GmbH';
  ed_Mailadresse.Text  := 'info@grid-data.com';
  ed_Telefon.Text      := '02191/4701-0';
end;

procedure Tfm_Produktanfrage.WebFormCreate(Sender: TObject);
begin
  Erstelle_Anfragememo;
end;

procedure Tfm_Produktanfrage.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  frm_webframe1.lbl_Titel.Caption := 'Merkliste / Produktanfrage';
  // Hier wird der angeklickte Menupunkt aktiv gesetzt
  el := document.getElementById('produkte');
  el['class'] := 'active';
end;

procedure Tfm_Produktanfrage.DlgClose(Sender: TObject);
begin
  frm_webframe1.Zeige_Formular(tfm_Produkte);
end;

procedure Tfm_Produktanfrage.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  frm_webframe1 := Tfrm_webframe.Create(Self);
  ed_Name := TEdit.Create(Self);
  ed_Firma := TEdit.Create(Self);
  ed_Mailadresse := TEdit.Create(Self);
  ed_Telefon := TEdit.Create(Self);
  WebButton1 := TButton.Create(Self);
  HTML := THTMLContainer.Create(Self);
  WebButton2 := TButton.Create(Self);
  Dlg := TMessageDlg.Create(Self);

  frm_webframe1.LoadFromForm;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  frm_webframe1.BeforeLoadDFMValues;
  frm_webframe1.WebElementActionList1.BeforeLoadDFMValues;
  ed_Name.BeforeLoadDFMValues;
  ed_Firma.BeforeLoadDFMValues;
  ed_Mailadresse.BeforeLoadDFMValues;
  ed_Telefon.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  HTML.BeforeLoadDFMValues;
  WebButton2.BeforeLoadDFMValues;
  Dlg.BeforeLoadDFMValues;
  try
    Name := 'fm_Produktanfrage';
    Width := 1471;
    Height := 671;
    CSSLibrary := cssBootstrap;
    FormContainer := 'webform';
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 24;
    WebLabel1.Top := 15;
    WebLabel1.Width := 37;
    WebLabel1.Height := 15;
    WebLabel1.Caption := 'Name*';
    WebLabel1.ElementFont := efCSS;
    WebLabel1.HeightStyle := ssAuto;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 24;
    WebLabel2.Top := 75;
    WebLabel2.Width := 35;
    WebLabel2.Height := 15;
    WebLabel2.Caption := 'Firma*';
    WebLabel2.ElementFont := efCSS;
    WebLabel2.HeightStyle := ssAuto;
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 25;
    WebLabel3.Top := 135;
    WebLabel3.Width := 67;
    WebLabel3.Height := 15;
    WebLabel3.Caption := 'Mailadresse*';
    WebLabel3.ElementFont := efCSS;
    WebLabel3.HeightStyle := ssAuto;
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 25;
    WebLabel4.Top := 195;
    WebLabel4.Width := 38;
    WebLabel4.Height := 15;
    WebLabel4.Caption := 'Telefon';
    WebLabel4.ElementFont := efCSS;
    WebLabel4.HeightStyle := ssAuto;
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 576;
    WebLabel5.Top := 15;
    WebLabel5.Width := 125;
    WebLabel5.Height := 15;
    WebLabel5.Caption := 'Anzufragende Produkte';
    WebLabel5.ElementFont := efCSS;
    WebLabel5.HeightStyle := ssAuto;
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    frm_webframe1.SetParentComponent(Self);
    frm_webframe1.Name := 'frm_webframe1';
    frm_webframe1.Left := 561;
    frm_webframe1.Top := 367;
    frm_webframe1.Width := 313;
    frm_webframe1.Height := 273;
    frm_webframe1.Color := clBtnFace;
    frm_webframe1.ParentBackground := False;
    frm_webframe1.ParentColor := False;
    frm_webframe1.TabOrder := 0;
    frm_webframe1.Visible := False;
    frm_webframe1.WebElementActionList1.Actions.Clear;
    with frm_webframe1.WebElementActionList1.Actions.Add do
    begin
      Event := heKeypress;
      ID := 'search';
      Name := 'act_Suchestarten';
      PreventDefault := False;
    end;
    ed_Name.SetParentComponent(Self);
    ed_Name.Name := 'ed_Name';
    ed_Name.Left := 25;
    ed_Name.Top := 32;
    ed_Name.Width := 450;
    ed_Name.Height := 22;
    ed_Name.ChildOrder := 2;
    ed_Name.ElementClassName := 'form-control';
    ed_Name.ElementFont := efCSS;
    ed_Name.HeightStyle := ssAuto;
    ed_Name.HeightPercent := 100.000000000000000000;
    ed_Name.WidthPercent := 100.000000000000000000;
    ed_Firma.SetParentComponent(Self);
    ed_Firma.Name := 'ed_Firma';
    ed_Firma.Left := 25;
    ed_Firma.Top := 92;
    ed_Firma.Width := 450;
    ed_Firma.Height := 22;
    ed_Firma.ChildOrder := 3;
    ed_Firma.ElementClassName := 'form-control';
    ed_Firma.ElementFont := efCSS;
    ed_Firma.HeightStyle := ssAuto;
    ed_Firma.HeightPercent := 100.000000000000000000;
    ed_Firma.WidthPercent := 100.000000000000000000;
    ed_Mailadresse.SetParentComponent(Self);
    ed_Mailadresse.Name := 'ed_Mailadresse';
    ed_Mailadresse.Left := 25;
    ed_Mailadresse.Top := 152;
    ed_Mailadresse.Width := 450;
    ed_Mailadresse.Height := 22;
    ed_Mailadresse.ChildOrder := 4;
    ed_Mailadresse.ElementClassName := 'form-control';
    ed_Mailadresse.ElementFont := efCSS;
    ed_Mailadresse.HeightStyle := ssAuto;
    ed_Mailadresse.HeightPercent := 100.000000000000000000;
    ed_Mailadresse.WidthPercent := 100.000000000000000000;
    ed_Telefon.SetParentComponent(Self);
    ed_Telefon.Name := 'ed_Telefon';
    ed_Telefon.Left := 25;
    ed_Telefon.Top := 212;
    ed_Telefon.Width := 450;
    ed_Telefon.Height := 22;
    ed_Telefon.ChildOrder := 4;
    ed_Telefon.ElementClassName := 'form-control';
    ed_Telefon.ElementFont := efCSS;
    ed_Telefon.HeightStyle := ssAuto;
    ed_Telefon.HeightPercent := 100.000000000000000000;
    ed_Telefon.WidthPercent := 100.000000000000000000;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 24;
    WebButton1.Top := 288;
    WebButton1.Width := 450;
    WebButton1.Height := 41;
    WebButton1.Caption := 'Produkte unverbindlich anfragen';
    WebButton1.ChildOrder := 10;
    WebButton1.ElementClassName := 'btn btn-primary';
    WebButton1.ElementFont := efCSS;
    WebButton1.HeightStyle := ssAuto;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    HTML.SetParentComponent(Self);
    HTML.Name := 'HTML';
    HTML.Left := 573;
    HTML.Top := 30;
    HTML.Width := 740;
    HTML.Height := 305;
    HTML.ElementClassName := 'form-control';
    HTML.ChildOrder := 12;
    HTML.ElementFont := efCSS;
    WebButton2.SetParentComponent(Self);
    WebButton2.Name := 'WebButton2';
    WebButton2.Left := 24;
    WebButton2.Top := 367;
    WebButton2.Width := 113;
    WebButton2.Height := 39;
    WebButton2.Caption := 'Plopp !';
    WebButton2.ChildOrder := 12;
    WebButton2.ElementClassName := 'btn btn-primary';
    WebButton2.ElementFont := efCSS;
    WebButton2.HeightStyle := ssAuto;
    WebButton2.HeightPercent := 100.000000000000000000;
    WebButton2.Visible := False;
    WebButton2.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton2, Self, 'OnClick', 'WebButton2Click');
    Dlg.SetParentComponent(Self);
    Dlg.Name := 'Dlg';
    Dlg.Left := 208;
    Dlg.Top := 367;
    Dlg.Width := 24;
    Dlg.Height := 24;
    Dlg.Buttons := [];
    Dlg.Opacity := 0.200000000000000000;
    Dlg.ElementButtonClassName := 'btn';
    Dlg.ElementDialogClassName := 'shadow-lg p-3 mb-5 bg-white rounded';
    Dlg.ElementTitleClassName := 'text-body';
    Dlg.ElementContentClassName := 'text-body';
    SetEvent(Dlg, Self, 'OnClose', 'DlgClose');
  finally
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    frm_webframe1.AfterLoadDFMValues;
    frm_webframe1.WebElementActionList1.AfterLoadDFMValues;
    ed_Name.AfterLoadDFMValues;
    ed_Firma.AfterLoadDFMValues;
    ed_Mailadresse.AfterLoadDFMValues;
    ed_Telefon.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    HTML.AfterLoadDFMValues;
    WebButton2.AfterLoadDFMValues;
    Dlg.AfterLoadDFMValues;
  end;
end;

end.