unit Globale_Prozeduren;

interface

Type

   T_Eintrag = Record
                   Produkt_ID  : Integer;
                   Artikelnr   : String;
                   Bezeichnung : String;
                   Menge       : Integer;
                   Preis       : String;
                 End;

  Function Entferne_Breaks (S : String):String;

VAR

   Sprache : Integer;
   Suchbegriff : String;
   Kategorie   : Integer;
   Treffer_pro_Seite : Integer;
   Datenbank_verbunden : Boolean;
   Merkliste           : Array of T_Eintrag;

implementation


Function Entferne_Breaks (S : String):String;
Begin
  while Pos('<br>', S) > 0 do
    Begin
      System.Delete(S,Pos('<br>',S),4);
    End;
  Result := S;
End;


Initialization

  Sprache := 1;
  Suchbegriff := '';
  Kategorie := 0;
  Treffer_pro_Seite := 10;
  Datenbank_verbunden := False;
  SetLength(Merkliste,0);
end.
