unit fo_statischer_Inhalt;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.Actions, Vcl.Controls, Vcl.StdCtrls,
  WEBLib.StdCtrls, Vcl.Forms, frame_webframe;

type
  Tfm_statischer_Inhalt = class(TWebForm)
    lbl_Ueberschrift: TWebLabel;
    frm_webframe1: Tfrm_webframe;
    procedure WebFormShow(Sender: TObject);
  private
    { Private-Deklarationen }
    Typ : Integer;
  public
    { Public-Deklarationen }
  end;

var
  fm_statischer_Inhalt: Tfm_statischer_Inhalt;

implementation

{$R *.dfm}

uses fo_Hauptformular, Globale_Prozeduren;


procedure Tfm_statischer_Inhalt.WebFormShow(Sender: TObject);
Var el : TJSElement;
begin
  case Typ of
    0 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Home';
          frm_webframe1.lbl_Titel.Caption := 'Home';
          el := document.getElementById('home');
        End;
    1 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Über uns';
          el := document.getElementById('ueberuns');
        End;
    3 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Messen';
          el := document.getElementById('messen');
        End;
    4 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Anfahrt';
          el := document.getElementById('anfahrt');
        End;
    5 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - AGB';
          el := document.getElementById('agb');
        End;
    6 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Datenschutz';
          el := document.getElementById('datenschutz');
        End;
    7 : Begin
          lbl_Ueberschrift.Caption := 'Hurra - Impressum';
          el := document.getElementById('impressum');
        End;
  end;
  el['class'] := 'active';

end;

end.