unit frame_webframe;

interface

uses
  System.SysUtils, System.Variants, System.Classes,
  Vcl.Graphics, Vcl.Controls, Vcl.Forms, Vcl.Dialogs, WEBLib.Actions,JS, Web,
  WEBLib.Graphics, WEBLib.Controls, WEBLib.Forms, WEBLib.Dialogs, Vcl.StdCtrls,
  WEBLib.StdCtrls, XData.Web.Client, XData.Web.Connection, WEBLib.ExtCtrls;


type
  Tfrm_webframe = class(TFrame)
    lbl_Titel: TLabel;
    lbl_Menu1: TLabel;
    lbl_Menu2: TLabel;
    lbl_Menu3: TLabel;
    lbl_Menu4: TLabel;
    lbl_Menu5: TLabel;
    lbl_Menu6: TLabel;
    lbl_Menu7: TLabel;
    lbl_Menu8: TLabel;
    lbl_deu: TLabel;
    lbl_eng: TLabel;
    ed_Suche: TEdit;
    lbl_Suche: TLabel;
    WebElementActionList1: TElementActionList;
    WebLabel1: TLabel;
//    procedure WebElementActionList1Actions9Execute(Sender: TObject;
//      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
//    procedure WebElementActionList1Actions10Execute(Sender: TObject;
//      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure lbl_Menu1Click(Sender: TObject);
    procedure lbl_Menu2Click(Sender: TObject);
    procedure lbl_Menu3Click(Sender: TObject);
    procedure lbl_Menu4Click(Sender: TObject);
    procedure lbl_Menu5Click(Sender: TObject);
    procedure lbl_Menu6Click(Sender: TObject);
    procedure lbl_Menu7Click(Sender: TObject);
    procedure lbl_Menu8Click(Sender: TObject);
    procedure lbl_deuClick(Sender: TObject);
    procedure lbl_engClick(Sender: TObject);
    procedure lbl_SucheClick(Sender: TObject);
    procedure ed_SucheChange(Sender: TObject);
    procedure WebElementActionList1Actions0Execute(Sender: TObject;
      Element: TJSHTMLElementRecord; Event: TJSEventParameter);
    procedure WebLabel1Click(Sender: TObject);
    procedure ed_SucheKeyPress(Sender: TObject; var Key: Char);
  private
    { Private-Deklarationen }
  public
    { Public-Deklarationen }
    frm: TForm;
    procedure Zeige_Formular(AInstanceClass: TFormClass);
    Procedure Menupunkte_umbenennen;
  protected procedure LoadDFMValues; override; end;

implementation

{$R *.dfm}

uses fo_Hauptformular, fo_statischer_Inhalt, Globale_Prozeduren, fo_AGB,
  fo_Anfahrt, fo_Datenschutz, fo_Impressum, fo_Messen, fo_Ueber_uns,
  fo_Produkte, fo_Dummy, fo_Produktdetails, fo_Produktanfrage;

procedure Tfrm_webframe.ed_SucheChange(Sender: TObject);
begin
  Suchbegriff := ed_Suche.Text;  // Testweise
end;

procedure Tfrm_webframe.ed_SucheKeyPress(Sender: TObject; var Key: Char);
begin
  if Ord(Key) = 13
   then
     Begin
       Suchbegriff := ed_Suche.Text;
       Zeige_Formular(tfm_Produkte);
     End;
end;

procedure Tfrm_webframe.lbl_deuClick(Sender: TObject);
begin
  Sprache := 1;
  Menupunkte_umbenennen;
end;

procedure Tfrm_webframe.lbl_engClick(Sender: TObject);
begin
  Sprache := 2;
  Menupunkte_umbenennen;
end;

procedure Tfrm_webframe.lbl_Menu1Click(Sender: TObject);
begin
  // Home
  Zeige_Formular(TForm1);
end;

procedure Tfrm_webframe.lbl_Menu2Click(Sender: TObject);
begin
  // Über uns
  Zeige_Formular(tfm_Ueber_uns);
end;

procedure Tfrm_webframe.WebElementActionList1Actions0Execute(Sender: TObject;
  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
begin
  if TJSKeyboardEvent(Event.JSEvent).key = 'Enter' then
  begin
    Event.JSEvent.stopPropagation;
    Event.JSEvent.preventDefault;
    Suchbegriff := ed_Suche.Text;
    Zeige_Formular(tfm_Produkte);
  end;
end;

procedure Tfrm_webframe.WebLabel1Click(Sender: TObject);
begin
  // Hier wird der "Warenkorb" angezeigt..

  If Length(Merkliste) > 0
    Then
      Zeige_Formular(tfm_Produktanfrage)
    else
      SHowMessage('Sie haben keine Produkte in Ihrer Merkliste.');
end;

//procedure Tfrm_webframe.WebElementActionList1Actions10Execute(Sender: TObject;
//  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
//begin
//  // Englische Sprache ausgewählt
//  Sprache := 2;
//  Menupunkte_umbenennen;
//end;
//
//procedure Tfrm_webframe.WebElementActionList1Actions9Execute(Sender: TObject;
//  Element: TJSHTMLElementRecord; Event: TJSEventParameter);
//begin
//  // Deutsche Sprache
//  Sprache := 1;
//  Menupunkte_umbenennen;
//end;

procedure Tfrm_webframe.lbl_Menu3Click(Sender: TObject);
begin
  // Produkte
  Suchbegriff := '';
  Zeige_Formular(tfm_Produkte);
end;

procedure Tfrm_webframe.lbl_Menu4Click(Sender: TObject);
begin
  // Messen
  Zeige_Formular(tfm_Messen);
end;

procedure Tfrm_webframe.lbl_Menu5Click(Sender: TObject);
begin
  // Anfahrt
  Zeige_Formular(tfm_Anfahrt);
end;

procedure Tfrm_webframe.lbl_Menu6Click(Sender: TObject);
begin
  // AGB
  Zeige_Formular(tfm_AGB);
end;

procedure Tfrm_webframe.lbl_Menu7Click(Sender: TObject);
begin
  // Datenschutz
  Zeige_Formular(tfm_Datenschutz);
end;

procedure Tfrm_webframe.lbl_Menu8Click(Sender: TObject);
begin
  // Impressum
  Zeige_Formular(tfm_Impressum);
end;

procedure Tfrm_webframe.lbl_SucheClick(Sender: TObject);
begin
  Suchbegriff := ed_Suche.Text;
  Zeige_Formular(tfm_Produkte);
end;

procedure Tfrm_webframe.Menupunkte_umbenennen;
begin
  case Sprache of
     1 : Begin
           lbl_Menu1.Caption := 'Home';
           lbl_Menu2.Caption := 'Über uns';
           lbl_Menu3.Caption := 'Produkte';
           lbl_Menu4.Caption := 'Messen';
           lbl_Menu5.Caption := 'Anfahrt';
           lbl_Menu6.Caption := 'AGB';
           lbl_Menu7.Caption := 'Datenschutz';
           lbl_Menu8.Caption := 'Impressum';
         End;
     2 : Begin
           lbl_Menu1.Caption := 'Home';
           lbl_Menu2.Caption := 'About us';
           lbl_Menu3.Caption := 'Products';
           lbl_Menu4.Caption := 'Fairs';
           lbl_Menu5.Caption := 'Arrival';
           lbl_Menu6.Caption := 'Conditions';
           lbl_Menu7.Caption := 'Privacy';
           lbl_Menu8.Caption := 'Imprint';
         End;
  end;
end;

procedure Tfrm_webframe.Zeige_Formular(AInstanceClass: TFormClass);
  procedure FormCreated(AForm: TObject);
  begin
    (AForm as TForm).Show;
  end;

begin
  if Assigned(frm) then
  begin
    frm.Close;
    frm.Free;
  end;
  if ed_Suche.Text  = ''
    then
      Begin
        Application.CreateForm(AInstanceClass, 'body', frm, @FormCreated);
      End
    else
      Begin
        Application.CreateForm(tfm_Produkte, 'body', frm, @FormCreated);
      End;
end;



procedure Tfrm_webframe.LoadDFMValues;
begin
  inherited LoadDFMValues;

  lbl_Titel := TLabel.Create('titel');
  lbl_Menu1 := TLabel.Create('lb_home');
  lbl_Menu2 := TLabel.Create('lb_ueberuns');
  lbl_Menu3 := TLabel.Create('lb_produkte');
  lbl_Menu4 := TLabel.Create('lb_messen');
  lbl_Menu5 := TLabel.Create('lb_anfahrt');
  lbl_Menu6 := TLabel.Create('lb_agb');
  lbl_Menu7 := TLabel.Create('lb_datenschutz');
  lbl_Menu8 := TLabel.Create('lb_impressum');
  lbl_deu := TLabel.Create('de');
  lbl_eng := TLabel.Create('uk');
  lbl_Suche := TLabel.Create('searchbutton');
  WebLabel1 := TLabel.Create('warenkorb');
  ed_Suche := TEdit.Create('search');
  WebElementActionList1 := TElementActionList.Create(Self);

  lbl_Titel.BeforeLoadDFMValues;
  lbl_Menu1.BeforeLoadDFMValues;
  lbl_Menu2.BeforeLoadDFMValues;
  lbl_Menu3.BeforeLoadDFMValues;
  lbl_Menu4.BeforeLoadDFMValues;
  lbl_Menu5.BeforeLoadDFMValues;
  lbl_Menu6.BeforeLoadDFMValues;
  lbl_Menu7.BeforeLoadDFMValues;
  lbl_Menu8.BeforeLoadDFMValues;
  lbl_deu.BeforeLoadDFMValues;
  lbl_eng.BeforeLoadDFMValues;
  lbl_Suche.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  ed_Suche.BeforeLoadDFMValues;
  WebElementActionList1.BeforeLoadDFMValues;
  try
    Name := 'frm_webframe';
    Left := 0;
    Top := 0;
    Width := 276;
    Height := 202;
    Color := clBtnFace;
    ParentBackground := False;
    ParentColor := False;
    TabOrder := 0;
    Visible := False;
    lbl_Titel.SetParentComponent(Self);
    lbl_Titel.Name := 'lbl_Titel';
    lbl_Titel.Left := 96;
    lbl_Titel.Top := 3;
    lbl_Titel.Width := 40;
    lbl_Titel.Height := 15;
    lbl_Titel.Caption := 'lbl_Titel';
    lbl_Titel.Color := clBtnFace;
    lbl_Titel.HeightPercent := 100.000000000000000000;
    lbl_Titel.WidthPercent := 100.000000000000000000;
    lbl_Menu1.SetParentComponent(Self);
    lbl_Menu1.Name := 'lbl_Menu1';
    lbl_Menu1.Left := 3;
    lbl_Menu1.Top := 8;
    lbl_Menu1.Width := 33;
    lbl_Menu1.Height := 15;
    lbl_Menu1.Caption := 'Home';
    lbl_Menu1.Color := clBtnFace;
    lbl_Menu1.HeightPercent := 100.000000000000000000;
    lbl_Menu1.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu1, Self, 'OnClick', 'lbl_Menu1Click');
    lbl_Menu2.SetParentComponent(Self);
    lbl_Menu2.Name := 'lbl_Menu2';
    lbl_Menu2.Left := 3;
    lbl_Menu2.Top := 29;
    lbl_Menu2.Width := 47;
    lbl_Menu2.Height := 15;
    lbl_Menu2.Caption := #220'ber uns';
    lbl_Menu2.Color := clBtnFace;
    lbl_Menu2.HeightPercent := 100.000000000000000000;
    lbl_Menu2.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu2, Self, 'OnClick', 'lbl_Menu2Click');
    lbl_Menu3.SetParentComponent(Self);
    lbl_Menu3.Name := 'lbl_Menu3';
    lbl_Menu3.Left := 3;
    lbl_Menu3.Top := 50;
    lbl_Menu3.Width := 48;
    lbl_Menu3.Height := 15;
    lbl_Menu3.Caption := 'Produkte';
    lbl_Menu3.Color := clBtnFace;
    lbl_Menu3.HeightPercent := 100.000000000000000000;
    lbl_Menu3.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu3, Self, 'OnClick', 'lbl_Menu3Click');
    lbl_Menu4.SetParentComponent(Self);
    lbl_Menu4.Name := 'lbl_Menu4';
    lbl_Menu4.Left := 3;
    lbl_Menu4.Top := 71;
    lbl_Menu4.Width := 40;
    lbl_Menu4.Height := 15;
    lbl_Menu4.Caption := 'Messen';
    lbl_Menu4.Color := clBtnFace;
    lbl_Menu4.HeightPercent := 100.000000000000000000;
    lbl_Menu4.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu4, Self, 'OnClick', 'lbl_Menu4Click');
    lbl_Menu5.SetParentComponent(Self);
    lbl_Menu5.Name := 'lbl_Menu5';
    lbl_Menu5.Left := 3;
    lbl_Menu5.Top := 92;
    lbl_Menu5.Width := 40;
    lbl_Menu5.Height := 15;
    lbl_Menu5.Caption := 'Anfahrt';
    lbl_Menu5.Color := clBtnFace;
    lbl_Menu5.HeightPercent := 100.000000000000000000;
    lbl_Menu5.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu5, Self, 'OnClick', 'lbl_Menu5Click');
    lbl_Menu6.SetParentComponent(Self);
    lbl_Menu6.Name := 'lbl_Menu6';
    lbl_Menu6.Left := 3;
    lbl_Menu6.Top := 113;
    lbl_Menu6.Width := 23;
    lbl_Menu6.Height := 15;
    lbl_Menu6.Caption := 'AGB';
    lbl_Menu6.Color := clBtnFace;
    lbl_Menu6.HeightPercent := 100.000000000000000000;
    lbl_Menu6.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu6, Self, 'OnClick', 'lbl_Menu6Click');
    lbl_Menu7.SetParentComponent(Self);
    lbl_Menu7.Name := 'lbl_Menu7';
    lbl_Menu7.Left := 3;
    lbl_Menu7.Top := 134;
    lbl_Menu7.Width := 65;
    lbl_Menu7.Height := 15;
    lbl_Menu7.Caption := 'Datenschutz';
    lbl_Menu7.Color := clBtnFace;
    lbl_Menu7.HeightPercent := 100.000000000000000000;
    lbl_Menu7.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu7, Self, 'OnClick', 'lbl_Menu7Click');
    lbl_Menu8.SetParentComponent(Self);
    lbl_Menu8.Name := 'lbl_Menu8';
    lbl_Menu8.Left := 3;
    lbl_Menu8.Top := 155;
    lbl_Menu8.Width := 59;
    lbl_Menu8.Height := 15;
    lbl_Menu8.Caption := 'Impressum';
    lbl_Menu8.Color := clBtnFace;
    lbl_Menu8.HeightPercent := 100.000000000000000000;
    lbl_Menu8.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Menu8, Self, 'OnClick', 'lbl_Menu8Click');
    lbl_deu.SetParentComponent(Self);
    lbl_deu.Name := 'lbl_deu';
    lbl_deu.Left := 96;
    lbl_deu.Top := 24;
    lbl_deu.Width := 38;
    lbl_deu.Height := 15;
    lbl_deu.Caption := 'lbl_deu';
    lbl_deu.Color := clBtnFace;
    lbl_deu.HeightPercent := 100.000000000000000000;
    lbl_deu.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_deu, Self, 'OnClick', 'lbl_deuClick');
    lbl_eng.SetParentComponent(Self);
    lbl_eng.Name := 'lbl_eng';
    lbl_eng.Left := 98;
    lbl_eng.Top := 45;
    lbl_eng.Width := 38;
    lbl_eng.Height := 15;
    lbl_eng.Caption := 'lbl_eng';
    lbl_eng.Color := clBtnFace;
    lbl_eng.HeightPercent := 100.000000000000000000;
    lbl_eng.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_eng, Self, 'OnClick', 'lbl_engClick');
    lbl_Suche.SetParentComponent(Self);
    lbl_Suche.Name := 'lbl_Suche';
    lbl_Suche.Left := 98;
    lbl_Suche.Top := 115;
    lbl_Suche.Width := 6;
    lbl_Suche.Height := 15;
    lbl_Suche.Caption := '';
    lbl_Suche.Color := clYellow;
    lbl_Suche.HeightPercent := 100.000000000000000000;
    lbl_Suche.Transparent := False;
    lbl_Suche.WidthPercent := 100.000000000000000000;
    SetEvent(lbl_Suche, Self, 'OnClick', 'lbl_SucheClick');
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 96;
    WebLabel1.Top := 66;
    WebLabel1.Width := 3;
    WebLabel1.Height := 15;
    WebLabel1.Color := 55295;
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.Transparent := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel1, Self, 'OnClick', 'WebLabel1Click');
    ed_Suche.SetParentComponent(Self);
    ed_Suche.Name := 'ed_Suche';
    ed_Suche.Left := 96;
    ed_Suche.Top := 87;
    ed_Suche.Width := 57;
    ed_Suche.Height := 22;
    ed_Suche.ChildOrder := 1;
    ed_Suche.HeightPercent := 100.000000000000000000;
    ed_Suche.WidthPercent := 100.000000000000000000;
    SetEvent(ed_Suche, Self, 'OnChange', 'ed_SucheChange');
    SetEvent(ed_Suche, Self, 'OnKeyPress', 'ed_SucheKeyPress');
    WebElementActionList1.SetParentComponent(Self);
    WebElementActionList1.Name := 'WebElementActionList1';
    WebElementActionList1.Actions.Clear;
    with WebElementActionList1.Actions.Add do
    begin
      Event := heKeypress;
      ID := 'search';
      Name := 'act_Suchestarten';
      PreventDefault := False;
      SetEvent(Self, 'OnExecute', 'WebElementActionList1Actions0Execute');
    end;
    WebElementActionList1.Left := 160;
    WebElementActionList1.Top := 144;
  finally
    lbl_Titel.AfterLoadDFMValues;
    lbl_Menu1.AfterLoadDFMValues;
    lbl_Menu2.AfterLoadDFMValues;
    lbl_Menu3.AfterLoadDFMValues;
    lbl_Menu4.AfterLoadDFMValues;
    lbl_Menu5.AfterLoadDFMValues;
    lbl_Menu6.AfterLoadDFMValues;
    lbl_Menu7.AfterLoadDFMValues;
    lbl_Menu8.AfterLoadDFMValues;
    lbl_deu.AfterLoadDFMValues;
    lbl_eng.AfterLoadDFMValues;
    lbl_Suche.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    ed_Suche.AfterLoadDFMValues;
    WebElementActionList1.AfterLoadDFMValues;
  end;
end;

end.
